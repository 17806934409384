import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';

import {
    calculateBlockHeight,
    extractLetters,
    extractNumbers,
    findDefaultPorts,
    findGroups,
    findParentGroupBlock,
    pasteGroupPorts,
    setDefaultPort,
    setPorts,
    updateHandleId,
} from '@repeat/constants';
import {
    IWorkspaceState,
    TSchemaConnection,
    TSchemaElementWithParams,
    TSchemaGroup,
    TSchemaNode,
    TState,
    WorkspaceModes,
} from '@repeat/models';

import { addExternalParameters } from './externalProjectInfoSlice';

import { actions } from '../..';
import { increaseUserBlocksCount } from '../helper';
import { initialState } from '../schemaSlice';

let leftBlockX: null | number = null;
let leftBlockY: null | number = null;

export const copyPasteItemsSlice = {
    setItemsToCopy: (state: IWorkspaceState) => {
        const { elements, wires } = state.schema.selectedItems;
        const workspaceMode = state.meta.mode;
        if (workspaceMode === WorkspaceModes.SUBMODEL) {
            return;
        }
        return {
            ...state,
            schema: {
                ...state.schema,
                itemsToCopy: {
                    elements,
                    wires,
                },
            },
        };
    },
    pasteItems: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            mousePosition?: { x: number; y: number; group?: false } | null;
            elementsToPaste: TSchemaNode[];
            wiresToPaste: TSchemaConnection[];
            groupsToPaste?: TSchemaGroup[];
        }>
    ) => {
        leftBlockX = null;
        leftBlockY = null;
        const { mode: workspaceMode, elementId: metaElementId } = state.meta;
        const {
            userBlocksCount: userBlocks,
            schemaItems: { elements },
        } = state.schema;
        const portsTypes = state.libraryPortTypes.items;
        const groups = state.schema.schemaItems.groups || [];
        let userBlocksCount = userBlocks || {};
        const groupsElements = payload.groupsToPaste?.map((group) => [...group.elements]).flat() || [];
        const userBlocksIds = [...payload.elementsToPaste, ...groupsElements]
            .filter((item) => item.data.type === 'userBlock' && item.data.blockId)
            .map((item) => item.data.blockId || '');
        if (userBlocksIds.length !== 0) {
            userBlocksCount = increaseUserBlocksCount({ ...userBlocksCount }, userBlocksIds);
        }

        const portsNodesToPaste = payload.elementsToPaste.filter(
            (el) => el.data.type === 'InPort' || el.data.type === 'OutPort'
        );

        const newGroups = payload.groupsToPaste ? payload.groupsToPaste : [];

        if (workspaceMode === WorkspaceModes.SUBMODEL) {
            return;
        }

        if (payload?.mousePosition?.group) {
            payload.elementsToPaste.forEach((block) => {
                if (leftBlockX === null) {
                    leftBlockX = block.position.x;
                }
                if (leftBlockY === null) {
                    leftBlockY = block.position.y;
                }
                if (leftBlockX > block.position.x) {
                    leftBlockX = block.position.x;
                }
                if (leftBlockY > block.position.y) {
                    leftBlockY = block.position.y;
                }
            });
        }

        const updateConnections = (schemaConnections: TSchemaConnection[]) => {
            if (!payload?.mousePosition?.group) {
                return [...schemaConnections, ...payload.wiresToPaste];
            }

            return [
                ...schemaConnections,
                ...payload.wiresToPaste.map((item) => {
                    if (!item.data.positionHandlers || !payload.mousePosition) {
                        return item;
                    }

                    return {
                        ...item,
                        data: {
                            ...item.data,
                            positionHandlers: item.data.positionHandlers.map((positionHandler: any) => ({
                                ...positionHandler,
                                x:
                                    positionHandler.x -
                                    (leftBlockX !== null ? leftBlockX : 0) +
                                    (payload?.mousePosition?.x ?? 0),
                                y:
                                    positionHandler.y -
                                    (leftBlockY !== null ? leftBlockY : 0) +
                                    (payload?.mousePosition?.y ?? 0),
                            })),
                        },
                    };
                }),
            ];
        };

        if (metaElementId && workspaceMode === WorkspaceModes.GROUP) {
            const libraryItems = state.libraryItems.items;

            const libraryItem = libraryItems.find((item) => item.type === 'group') || null;

            const { defaultInputPort, defaultOutputPort } = findDefaultPorts(libraryItem);
            const parentGroupBlockId = groups.find((group) => group.id.toString() === metaElementId)?.parentGroupId;
            const parentGroupBlock = findParentGroupBlock(groups, elements, metaElementId);
            const groupsUpdated = groups.map((group) => {
                if (group.id.toString() === metaElementId) {
                    const elements = [
                        ...group.elements,
                        ...payload.elementsToPaste.map((item) => {
                            if (payload.mousePosition) {
                                if (!payload.mousePosition.group) {
                                    return {
                                        ...item,
                                        position: { x: payload.mousePosition.x, y: payload.mousePosition.y },
                                    };
                                }
                                if (payload.mousePosition.group) {
                                    return {
                                        ...item,
                                        position: {
                                            x:
                                                item.position.x -
                                                (leftBlockX !== null ? leftBlockX : 0) +
                                                payload.mousePosition.x,
                                            y:
                                                item.position.y -
                                                (leftBlockY !== null ? leftBlockY : 0) +
                                                payload.mousePosition.y,
                                        },
                                    };
                                }
                            }
                            return { ...item };
                        }),
                    ];
                    const wires = updateConnections(group.wires);
                    return { ...group, elements, wires };
                }
                return group;
            });
            const stateUpdated = {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        groups: [...groupsUpdated, ...newGroups],
                    },
                    userBlocksCount,
                },
            };

            if (portsNodesToPaste.length !== 0) {
                const {
                    elements: groupElements,
                    parameters,
                    proxyMap,
                } = pasteGroupPorts(stateUpdated, portsNodesToPaste);
                if (parentGroupBlockId === null && parentGroupBlock) {
                    /// если блок Группа на верхнем уровне
                    const elements = stateUpdated.schema.schemaItems.elements.map((el) => {
                        if (el.id === parentGroupBlock.id && parameters && proxyMap) {
                            const inputPortDetails = portsTypes.find(
                                (type) => type.type === defaultInputPort.typeConnection
                            ) || {
                                ...setDefaultPort(defaultInputPort.name, portsTypes),
                                type: defaultInputPort.typeConnection,
                            };
                            const outputPortDetails = portsTypes.find(
                                (type) => type.type === defaultOutputPort.typeConnection
                            ) || {
                                ...setDefaultPort(defaultOutputPort.name, portsTypes),
                                type: defaultOutputPort.typeConnection,
                            };

                            const availablePorts = setPorts(
                                parameters,
                                defaultInputPort,
                                defaultOutputPort,
                                el.data.availablePorts,
                                inputPortDetails,
                                outputPortDetails
                            );
                            const height = calculateBlockHeight(availablePorts);
                            return {
                                ...el,
                                data: { ...el.data, elemParams: parameters, proxyMap, availablePorts },
                                height,
                            };
                        }
                        return el;
                    });
                    const groups = stateUpdated.schema.schemaItems.groups.map((group) => {
                        if (group.id.toString() === metaElementId && groupElements) {
                            return { ...group, elements: groupElements };
                        }
                        return group;
                    });
                    const elementsWithParams = stateUpdated.schema.elementsWithParams.map((el) => {
                        if (el.id.toString() === parentGroupBlock.id && parameters) {
                            return { ...el, elemParams: parameters };
                        }
                        return el;
                    });

                    return {
                        ...stateUpdated,
                        schema: {
                            ...stateUpdated.schema,
                            schemaItems: {
                                ...stateUpdated.schema.schemaItems,
                                elements,
                                groups,
                            },
                            userBlocksCount,
                            elementsWithParams,
                        },
                    };
                }

                if (parentGroupBlockId !== null && parentGroupBlock) {
                    const groups = stateUpdated.schema.schemaItems.groups.map((group) => {
                        if (group.id.toString() === parentGroupBlockId) {
                            const elements = group.elements.map((el) => {
                                if (el.id === parentGroupBlock.id && parameters && proxyMap) {
                                    //
                                    const inputPortDetails = portsTypes.find(
                                        (type) => type.type === defaultInputPort.typeConnection
                                    ) || {
                                        ...setDefaultPort(defaultInputPort.name, portsTypes),
                                        type: defaultInputPort.typeConnection,
                                    };
                                    const outputPortDetails = portsTypes.find(
                                        (type) => type.type === defaultOutputPort.typeConnection
                                    ) || {
                                        ...setDefaultPort(defaultOutputPort.name, portsTypes),
                                        type: defaultOutputPort.typeConnection,
                                    };

                                    //

                                    const availablePorts = setPorts(
                                        parameters,
                                        defaultInputPort,
                                        defaultOutputPort,
                                        el.data.availablePorts,
                                        inputPortDetails,
                                        outputPortDetails
                                    );
                                    const height = calculateBlockHeight(availablePorts);
                                    return {
                                        ...el,
                                        data: { ...el.data, elemParams: parameters, proxyMap, availablePorts },
                                        height,
                                    };
                                }
                                return el;
                            });
                            return { ...group, elements };
                        }
                        if (group.id.toString() === metaElementId && groupElements) {
                            return { ...group, elements: groupElements };
                        }
                        return group;
                    });
                    return {
                        ...stateUpdated,
                        schema: {
                            ...state.schema,
                            schemaItems: { ...state.schema.schemaItems, groups },
                            userBlocksCount,
                        },
                    };
                }
            }
            return stateUpdated;
        }

        const updateElements = (schemaElements: TSchemaNode[]) => {
            return [
                ...schemaElements,
                ...payload.elementsToPaste.map((item) => {
                    if (payload.mousePosition) {
                        if (!payload.mousePosition.group) {
                            return {
                                ...item,
                                position: { x: payload.mousePosition.x, y: payload.mousePosition.y },
                            };
                        }
                        if (payload.mousePosition.group) {
                            return {
                                ...item,
                                position: {
                                    x:
                                        item.position.x -
                                        (leftBlockX !== null ? leftBlockX : 0) +
                                        payload.mousePosition.x,
                                    y:
                                        item.position.y -
                                        (leftBlockY !== null ? leftBlockY : 0) +
                                        payload.mousePosition.y,
                                },
                            };
                        }
                    }
                    return { ...item };
                }),
            ];
        };

        if (workspaceMode === WorkspaceModes.FSM_EDITOR) {
            const elements = state.schema.schemaItems.elements.map((el) => {
                if (el.id === metaElementId) {
                    const submodelItems = el.data.submodelItems || { elements: [], wires: [] };
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            submodelItems: {
                                ...submodelItems,
                                elements: updateElements(submodelItems.elements),
                                wires: [...submodelItems.wires, ...payload.wiresToPaste],
                            },
                        },
                    };
                }
                return el;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    selectedItems: initialState.selectedItems,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        elements,
                    },
                },
            };
        }

        const stateUpdated = {
            ...state,
            schema: {
                ...state.schema,
                selectedItems: initialState.selectedItems,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: updateElements(state.schema.schemaItems.elements),
                    wires: updateConnections(state.schema.schemaItems.wires),
                    groups: payload.groupsToPaste ? [...groups, ...payload.groupsToPaste] : groups,
                },
                userBlocksCount,
                elementsWithParams: [
                    ...state.schema.elementsWithParams,
                    ...payload.elementsToPaste.map(
                        (n: TSchemaNode) =>
                            ({
                                id: n.data.id,

                                name: n.data.name,
                                index: n.data.index,
                                elemParams: [...n.data.elemParams],
                            } as TSchemaElementWithParams)
                    ),
                ],
            },
        };

        // TODO const portsNodesToPaste = payload.elementsToPaste.filter(
        //     (el) => el.data.type === 'InPort' || el.data.type === 'OutPort'
        // );
        if (portsNodesToPaste.length !== 0) {
            const portsInfo = addExternalParameters(stateUpdated, portsNodesToPaste);
            if (portsInfo && 'elements' in portsInfo) {
                const { elements, externalInfo, proxyMap } = portsInfo;
                return {
                    ...stateUpdated,
                    schema: {
                        ...stateUpdated.schema,
                        schemaItems: { ...stateUpdated.schema.schemaItems, elements, userBlocks: userBlocksCount },
                        externalInfo,
                        proxyMap,
                    },
                };
            }
        }
        return stateUpdated;
    },
};

// TODO make using AppDispatch again
export const copyItems = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    dispatch(actions.setItemsToCopy());
};

// TODO make using AppDispatch and RootStateFn again
export const pasteItems = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
    const state = getState() as TState;
    const {
        schemaItems: { elements, groups },
        itemsToCopy: { elements: elementsToCopy, wires: wiresToCopy },
    } = state.workspace.schema;
    const metaElementId = state.workspace.meta.elementId;
    const itemsToCopy: (TSchemaConnection | TSchemaNode)[] = [...elementsToCopy, ...wiresToCopy];
    const elementsMap = new Map();
    let elementsToPaste: TSchemaNode[] = [];
    let elementsToPasteUpd: TSchemaNode[] = [];

    const groupsState = groups || [];
    const elementsToCopyGroupTypeIds = elementsToCopy.filter((el) => el.data.type === 'group').map((el) => el.id);
    const groupsToCopy = groupsState.filter((group) => elementsToCopyGroupTypeIds.includes(group.id.toString())); // группы, найденные по айди копируемых элементов с типом группа
    const groupsToCopyInner = groupsToCopy.map((group) => findGroups(group, groupsState)).flat(); // все вложенные группы
    const groupsToCopyAll = [...groupsToCopy, ...groupsToCopyInner];

    const wiresToPaste: TSchemaConnection[] = [];
    const groupsToPaste: TSchemaGroup[] = [];

    const oldGroupsIdByNewGroupsId = new Map();
    const groupsNewByOldMap = new Map();

    const groupsOldByNewMap = new Map();

    const generateElementsToPaste = (arrayToCopy: TSchemaNode[], elementsState: TSchemaNode[]) => {
        const arrayToPaste: TSchemaNode[] = [];
        arrayToCopy.forEach((el: TSchemaNode) => {
            const letterIndex = extractLetters(el.data.index);
            const elementsWithCurrentIndex = elementsState.filter(
                (element: TSchemaNode) => extractLetters(element.data.index) === letterIndex
            );
            const elementsToCopyWithCurrentIndex = arrayToCopy.filter(
                (element: TSchemaNode) => extractLetters(element.data.index) === letterIndex
            );
            const indexInsideType = elementsToCopyWithCurrentIndex.indexOf(el) + 1;
            const maxIndexDisplayOfCurrentTypeElement = elementsWithCurrentIndex.reduce(
                (acc: TSchemaNode, curr: TSchemaNode) =>
                    extractNumbers(acc?.data.index) > extractNumbers(curr?.data.index) ? acc : curr
            )?.data.index;

            const newElementIndex = extractNumbers(maxIndexDisplayOfCurrentTypeElement) + indexInsideType;
            const newElementIndexDisplay = `${letterIndex}${newElementIndex}`;
            const indexInItems = itemsToCopy.indexOf(el);

            const newElementId = Date.now() + indexInItems;
            if (el.data.type === 'group' && groups) {
                groupsNewByOldMap.set(el.data.id, newElementId);
                groupsOldByNewMap.set(newElementId, el.data.id);
                const groupToCopy = groups.find((group) => group.id.toString() === el.id);

                if (groupToCopy) {
                    const innerGroups = findGroups(groupToCopy, groups).map((group, index) => {
                        groupsNewByOldMap.set(group.id, newElementId + index + 1);
                        groupsOldByNewMap.set(newElementId + index + 1, group.id);
                        return {
                            ...group,
                            id: newElementId + index + 1,
                        };
                    });

                    groupsToPaste.push(
                        {
                            ...groupToCopy,
                            id: newElementId,
                            parentGroupId: metaElementId,
                        },
                        ...innerGroups
                    );
                    oldGroupsIdByNewGroupsId.set(newElementId, groupToCopy.id);
                }
            }

            elementsMap.set(el.data.id.toString(), newElementId.toString());

            const connectedElementsPortsIds: string[] = [];
            const elementsToCopyIds: string[] = arrayToCopy.map((el) => el.id);
            wiresToCopy.forEach((wire) => {
                if (elementsToCopyIds.includes(wire.source) && elementsToCopyIds.includes(wire.target)) {
                    connectedElementsPortsIds.push(wire.sourceHandle, wire.targetHandle);
                }
            });

            const newElementAvailablePorts = el.data.availablePorts.map((port, index) => {
                const portFullName = `${el.id}-${index + 1}-${port.name}`;
                if (!connectedElementsPortsIds.includes(portFullName)) {
                    return { ...port, isConnected: false };
                } else {
                    return port;
                }
            });
            arrayToPaste.push({
                ...el,
                id: newElementId.toString(),
                data: {
                    ...el.data,
                    index: newElementIndexDisplay,
                    id: newElementId,
                    availablePorts: newElementAvailablePorts,
                },
            });
        });
        return arrayToPaste;
    };

    if (elementsToCopy.length) {
        const currentGroup = groupsState.find((group) => group.id.toString() === metaElementId?.toString());
        const elementsToGenerate = currentGroup ? currentGroup.elements : elements;
        elementsToPaste = generateElementsToPaste(elementsToCopy, elementsToGenerate);
    }
    const elementsInGroupMap = new Map();

    const findNewGroupParentId = (id: number, metaElementId: string | null) => {
        const oldGroupId = groupsOldByNewMap.get(id);
        const oldGroup = groupsToCopyAll.find((g) => g.id === oldGroupId);

        if (oldGroup && oldGroup.parentGroupId) {
            return groupsNewByOldMap.get(Number(oldGroup.parentGroupId))?.toString();
        }
        return metaElementId;
    };

    const groupsToPasteWithUpdatedIds = groupsToPaste.map((group) => {
        const elements = group.elements.map((el, i) => {
            const newId = el.data.type === 'group' ? groupsNewByOldMap.get(el.data.id) : group.id + el.data.id + i;
            elementsInGroupMap.set(el.id, newId.toString());

            return { ...el, id: newId.toString(), data: { ...el.data, id: newId } };
        });
        const wires = group.wires.map((wire, i) => {
            const id = group.id + Number(wire.id) + i + 1;
            const source = elementsInGroupMap.get(wire.source);
            const target = elementsInGroupMap.get(wire.target);
            const sourceHandle = updateHandleId(wire.sourceHandle, source);
            const targetHandle = updateHandleId(wire.targetHandle, target);
            return { ...wire, id: id.toString(), source, target, sourceHandle, targetHandle };
        });
        const parentGroupId = findNewGroupParentId(group.id, metaElementId);

        return { ...group, elements, wires, parentGroupId };
    });

    elementsToPasteUpd = elementsToPaste.map((el) => {
        if (el.data.type === 'group') {
            const proxyMap = el.data.proxyMap;
            const proxyMapParams = proxyMap
                ? proxyMap.params.map((param) => ({
                      ...param,
                      internalBlockId: Number(elementsInGroupMap.get(param.internalBlockId.toString())),
                  }))
                : [];
            const proxyMapUpd = proxyMap ? { ...proxyMap, params: proxyMapParams } : { params: [], props: [] };
            return { ...el, data: { ...el.data, proxyMap: proxyMapUpd } };
        }
        return el;
    });

    const groupsToPasteWithUpdatedIdsUpd = groupsToPasteWithUpdatedIds.map((group) => {
        const elements = group.elements.map((el) => {
            if (el.data.type === 'group') {
                const proxyMap = el.data.proxyMap;
                const proxyMapParams = proxyMap
                    ? proxyMap.params.map((param) => ({
                          ...param,
                          internalBlockId: Number(elementsInGroupMap.get(param.internalBlockId.toString())),
                      }))
                    : [];
                const proxyMapUpd = proxyMap ? { ...proxyMap, params: proxyMapParams } : { params: [], props: [] };
                return { ...el, data: { ...el.data, proxyMap: proxyMapUpd } };
            }
            return el;
        });
        return { ...group, elements };
    });

    if (wiresToCopy.length) {
        wiresToCopy.forEach((wire: TSchemaConnection) => {
            const firstElement: TSchemaNode = elementsToCopy.find((el) => el.id === wire.source) as TSchemaNode;
            const secondElement: TSchemaNode = elementsToCopy.find((el) => el.id === wire.target) as TSchemaNode;
            const indexInItems = itemsToCopy.indexOf(wire);

            if (firstElement && secondElement) {
                const source = elementsMap.get(wire.source);
                const target = elementsMap.get(wire.target);
                const sourceHandle = updateHandleId(wire.sourceHandle, source);
                const targetHandle = updateHandleId(wire.targetHandle, target);

                wiresToPaste.push({
                    ...wire,
                    id: (Date.now() + indexInItems).toString(),
                    source,
                    target,
                    sourceHandle,
                    targetHandle,
                });
            }
        });
    }
    const mousePosition = localStorage.getItem('mousePosition');
    dispatch(
        actions.pasteItems({
            ...(mousePosition && {
                mousePosition: {
                    ...JSON.parse(mousePosition),
                    group: itemsToCopy.length > 1,
                },
            }),
            elementsToPaste: elementsToPasteUpd,
            wiresToPaste,
            groupsToPaste: groupsToPasteWithUpdatedIdsUpd,
        })
    );
};
