import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow, useStore } from 'reactflow';

import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { TSchemaConnection, TWorkspaceMode } from '@repeat/models';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Badge } from '@repeat/ui-kit';

import ClickableBaseEdge from './ClickableBaseEdge';

import { useReadOnlyMode } from '../../../../hooks/useReadOnlyMode';
import { messages } from '../FSMTranslations';
import { getEdgeParams } from '../FSMUtils';
import { SFSMLabel } from '../SFSMStyles';

import './FloatingEdge.css';

export function FloatingConnectionLine(props: any) {
    const { toX, toY, fromPosition, toPosition, fromNode } = props;

    if (!fromNode) {
        return null;
    }

    const targetNodeConnection = {
        id: 'connection-target',
        width: 1,
        height: 1,
        positionAbsolute: { x: toX, y: toY },
    };

    const { sx, sy } = getEdgeParams(fromNode, targetNodeConnection);
    const [edgePath] = getBezierPath({
        sourceX: fromNode.type === 'fsm-start' ? fromNode.position.x + 24 : sx,
        sourceY: fromNode.type === 'fsm-start' ? fromNode.position.y + 24 : sy,
        sourcePosition: fromPosition,
        targetPosition: toPosition,
        targetX: toX,
        targetY: toY,
    });

    return (
        <g>
            <path fill='none' stroke='#222' strokeWidth={1} className='animated' d={edgePath} />
            <circle cx={toX} cy={toY} fill='#fff' r={3} stroke='#222' strokeWidth={1} />
        </g>
    );
}

const SBadgeWrapper = styled.div`
    position: absolute;
    left: -8px;
    top: -18px;
`;

export function FloatingEdge(props: EdgeProps) {
    const { id, source, target, markerEnd, style, data, selected } = props;

    const { formatMessage } = useIntl();
    const reactFlowInstance = useReactFlow();

    const dispatch = useAppDispatch();
    const metaElementId = useAppSelector(workspaceSelectors.workspaceMetaElementId) || null;
    const node = useAppSelector(workspaceSelectors.getSchemaNodeById(metaElementId));
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const prevWorkspaceMode = useAppSelector((state) => state.workspace.meta.previousMode) as TWorkspaceMode;

    const connections = (node?.data?.submodelItems?.wires || []).filter(
        (connection: TSchemaConnection) => connection.source === source
    );

    const { isReadOnlyMode } = useReadOnlyMode(workspaceMode, prevWorkspaceMode as TWorkspaceMode);

    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    const pathStyle = useMemo(() => (selected ? { ...style, strokeWidth: 2, stroke: 'black' } : style), [selected]);
    const [positionHandlers, setPositionHandlers] = useState(data?.positionHandlers ?? []);

    useEffect(() => {
        setPositionHandlers(data?.positionHandlers ?? []);
    }, [data?.positionHandlers]);

    if (!sourceNode || !targetNode) {
        return null;
    }

    if (sourceNode.id === targetNode.id) {
        return null;
    }

    if (targetNode.type === 'fsm-start') {
        return null;
    }

    const handleChangeCondition = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            workspaceActions.setFSMConnectionPropertyValue({
                id,
                fsmBlockId: metaElementId,
                propertyName: 'condition',
                propertyValue: event.target.value,
            })
        );
    };

    const handleChangeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            workspaceActions.setFSMConnectionPropertyValue({
                id,
                fsmBlockId: metaElementId,
                propertyName: 'action',
                propertyValue: event.target.value,
            })
        );
    };

    const handleChangeHandlers = (handlers: any) => {
        dispatch(
            workspaceActions.setFSMConnectionPositionHandlers({
                id,
                fsmBlockId: metaElementId,
                value: handlers,
            })
        );
    };

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

    const edgeSegmentsCount = positionHandlers.length + 1;
    const edgeSegmentsArray = [];

    // calculate the origin and destination of all the segments
    let startLabelX, startLabelY;
    const labelSegmentNumber = Math.floor(positionHandlers.length / 2);
    for (let i = 0; i < edgeSegmentsCount; i++) {
        let segmentSourceX, segmentSourceY, segmentTargetX, segmentTargetY;

        if (i === 0) {
            segmentSourceX = sourceNode.type === 'fsm-start' ? props.sourceX : sx;
            segmentSourceY = sourceNode.type === 'fsm-start' ? props.sourceY : sy;
        } else {
            const handler = positionHandlers[i - 1];
            segmentSourceX = handler.x;
            segmentSourceY = handler.y;
        }

        if (i === edgeSegmentsCount - 1) {
            segmentTargetX = tx;
            segmentTargetY = ty;
        } else {
            const handler = positionHandlers[i];
            segmentTargetX = handler.x;
            segmentTargetY = handler.y;
        }

        const [edgePath, labelX, labelY] = getBezierPath({
            sourceX: segmentSourceX,
            sourceY: segmentSourceY,
            sourcePosition: sourcePos,
            targetPosition: targetPos,
            targetX: segmentTargetX,
            targetY: segmentTargetY,
        });
        edgeSegmentsArray.push({ edgePath, labelX, labelY });

        if (i === labelSegmentNumber) {
            startLabelX = labelX;
            startLabelY = labelY;
        }
    }

    return (
        <>
            {edgeSegmentsArray.map(({ edgePath, labelX, labelY }, index) => (
                <ClickableBaseEdge
                    onClick={(event: any) => {
                        if (!selected) {
                            return;
                        }

                        const position = reactFlowInstance.screenToFlowPosition({
                            x: event.clientX,
                            y: event.clientY,
                        });

                        const newPositionHandlers = [...positionHandlers];
                        newPositionHandlers.splice(index, 0, {
                            x: position.x,
                            y: position.y,
                        });

                        handleChangeHandlers(newPositionHandlers);
                    }}
                    key={`edge${id}_segment${index}`}
                    path={edgePath}
                    markerEnd={index === edgeSegmentsArray.length - 1 ? markerEnd : ''}
                    style={pathStyle}
                />
            ))}
            {positionHandlers.map(
                ({ x, y, active }: { x: number; y: number; active: number }, handlerIndex: number) => (
                    <EdgeLabelRenderer key={`edge${id}_handler${handlerIndex}`}>
                        <div
                            className={`nopan positionHandlerContainer ${
                                selected === true ? 'selected-edge' : 'default-edge'
                            }`}
                            style={{
                                transform: `translate(-50%, -50%) translate(${x}px,${y}px)`,
                            }}
                        >
                            <div
                                className={`positionHandlerEventContainer ${active} ${
                                    `${active ?? -1}` !== '-1' ? 'active' : ''
                                }`}
                                data-active={active ?? -1}
                                // mouse move is used to move the handler when its been mousedowned on
                                onMouseMove={(event: any) => {
                                    const activeEdge = parseInt(event.target.dataset.active ?? -1);
                                    if (activeEdge === -1) {
                                        return;
                                    }

                                    const position = reactFlowInstance.screenToFlowPosition({
                                        x: event.clientX,
                                        y: event.clientY,
                                    });

                                    const newPositionHandlers = positionHandlers.map((handler: any, index: string) => {
                                        if (+index === +handlerIndex) {
                                            return {
                                                x: position.x,
                                                y: position.y,
                                                active: activeEdge,
                                            };
                                        }
                                        return { ...handler };
                                    });

                                    setPositionHandlers(newPositionHandlers);
                                }}
                                // mouse up is used to release all the handlers
                                onMouseUp={() => {
                                    const newPositionHandlers = positionHandlers.map((handler: any, index: string) => ({
                                        ...handler,
                                        active: -1,
                                    }));

                                    setPositionHandlers(newPositionHandlers);
                                    handleChangeHandlers(newPositionHandlers);
                                }}
                            >
                                <button
                                    className='positionHandler'
                                    data-active={active ?? -1}
                                    // mouse down is used to activate the handler
                                    onMouseDown={() => {
                                        const newPositionHandlers = positionHandlers.map(
                                            (handler: any, index: string) => {
                                                if (+index === +handlerIndex) {
                                                    return {
                                                        ...handler,
                                                        active: 1,
                                                    };
                                                }
                                                return { ...handler };
                                            }
                                        );

                                        setPositionHandlers(newPositionHandlers);
                                    }}
                                    // right click is used to delete the handler
                                    onContextMenu={(event) => {
                                        event.preventDefault();
                                        const newPositionHandlers = [...positionHandlers];
                                        newPositionHandlers.splice(handlerIndex, 1);

                                        handleChangeHandlers(newPositionHandlers);
                                    }}
                                ></button>
                            </div>
                        </div>
                    </EdgeLabelRenderer>
                )
            )}
            <EdgeLabelRenderer>
                <SFSMLabel
                    style={{
                        transform: `translate(-50%, -50%) translate(${startLabelX}px,${startLabelY}px)`,
                        pointerEvents: 'all',
                    }}
                    className='nodrag nopan'
                >
                    <div>
                        <SBadgeWrapper>
                            <Badge
                                size={'extra-small'}
                                text={`${connections.findIndex(({ id: connectionId }) => connectionId === id) + 1}`}
                                color={'var(--ui-primary)'}
                            />
                        </SBadgeWrapper>
                        <div>
                            <input
                                placeholder={formatMessage(messages[TranslationKey.FSM_STATE_CONDITION])}
                                type='text'
                                value={data?.condition ?? ''}
                                {...(isReadOnlyMode && { onChange: handleChangeCondition })}
                            />
                        </div>
                        <div>
                            <input
                                placeholder={formatMessage(messages[TranslationKey.FSM_STATE_ACTION])}
                                type='text'
                                value={data?.action ?? ''}
                                {...(isReadOnlyMode && { onChange: handleChangeAction })}
                            />
                        </div>
                    </div>
                </SFSMLabel>
            </EdgeLabelRenderer>
        </>
    );
}
