import { current, PayloadAction } from '@reduxjs/toolkit';

import {
    IWorkspaceState,
    SchemaItemTypes,
    TSchemaConnection,
    TSchemaItemType,
    TSchemaNode,
    TWorkspaceMode,
    WorkspaceModes,
} from '@repeat/models';

import { initialState } from '../schemaSlice';

export const SelectElementsSlice = {
    // selection
    setSelectedItem: (state: IWorkspaceState, action: PayloadAction<{ id: string; type: TSchemaItemType }>) => {
        const { id, type } = action.payload;
        const metaElementId = state.meta.elementId;
        const groups = state.schema.schemaItems.groups || [];
        let nodes: TSchemaNode[] = [];
        let connections: TSchemaConnection[] = [];

        if (metaElementId === null) {
            nodes =
                type === SchemaItemTypes.NODE
                    ? [...state.schema.schemaItems.elements].filter((node: TSchemaNode) => node.id === id)
                    : [];
            connections =
                type === SchemaItemTypes.CONNECTION
                    ? [...state.schema.schemaItems.wires].filter(
                          (connection: TSchemaConnection) => connection.id === id
                      )
                    : [];
        } else {
            const currentGroup = current(groups).find((group) => group.id.toString() === metaElementId);
            nodes =
                type === SchemaItemTypes.NODE && currentGroup
                    ? [...currentGroup.elements].filter((node: TSchemaNode) => node.id === id)
                    : [];
            connections =
                type === SchemaItemTypes.CONNECTION && currentGroup
                    ? [...currentGroup.wires].filter((connection: TSchemaConnection) => connection.id === id)
                    : [];
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                selectedItems: {
                    elements: nodes,
                    wires: connections,
                },
            },
        };
    },
    setSelectedItems: (state: IWorkspaceState, action: PayloadAction<{ ids: string[]; type: TSchemaItemType }>) => {
        const { ids, type } = action.payload;
        const { mode, elementId, groupId, isUserBlockEditor } = state.meta;
        const { elements, wires, groups, userBlockGroups } = state.schema.schemaItems;

        const currentGroup = groups?.find((group) => group.id.toString() === elementId);
        const workspaceMetaUserBlockId = state.meta.userBlockId;
        const isGettingUserBlockViewMode =
            workspaceMetaUserBlockId &&
            (mode === WorkspaceModes.DEMO || (mode === WorkspaceModes.GROUP && !isUserBlockEditor));

        const filterNodesByIds = (nodes: TSchemaNode[]) => nodes.filter((node) => ids.includes(node.id));
        const filterConnectionsByIds = (connections: TSchemaConnection[]) =>
            connections.filter((connection) => ids.includes(connection.id));

        let nodes: TSchemaNode[] = [];
        let connections: TSchemaConnection[] = [];

        let currentGroupUserBlock;
        if (type === SchemaItemTypes.NODE) {
            const nodesFilteredMain = filterNodesByIds(elements);
            let currentElement;

            if (groupId === null) {
                currentElement = elements.find((el) => el.id === elementId);
            } else {
                const currentGroup = isGettingUserBlockViewMode
                    ? userBlockGroups?.find((group) => group.id.toString() === elementId) ||
                      userBlockGroups?.find((group) => group.parentGroupId === null)
                    : groups?.find((group) => group.id.toString() === groupId);
                currentGroupUserBlock = currentGroup;

                if (currentGroup && !isGettingUserBlockViewMode) {
                    currentElement = currentGroup.elements.find((el) => el.id === elementId);
                }
            }

            const nodesFilteredSubmodel = currentElement?.data.submodelItems?.elements
                ? filterNodesByIds(currentElement.data.submodelItems.elements)
                : [];
            const nodesFilteredGroup = isGettingUserBlockViewMode
                ? filterNodesByIds(currentGroupUserBlock?.elements || [])
                : filterNodesByIds(currentGroup?.elements || []);

            const setFilteredNodes = (mode: TWorkspaceMode) => {
                switch (mode) {
                    case WorkspaceModes.SUBMODEL:
                    case WorkspaceModes.FSM_EDITOR:
                        return nodesFilteredSubmodel;
                    case WorkspaceModes.GROUP:
                        return nodesFilteredGroup;
                    default:
                        return nodesFilteredMain;
                }
            };

            nodes = ids.length ? setFilteredNodes(mode) : [];
            connections = state.schema.selectedItems.wires;
        } else if (type === SchemaItemTypes.CONNECTION) {
            const currentElement = state.schema.schemaItems.elements.find((el: TSchemaNode) => el.id === elementId);
            const connectionsFilteredMain = filterConnectionsByIds(wires);
            const connectionsFilteredGroup = filterConnectionsByIds(currentGroup?.wires || []);
            const connectionsFilteredSubmodel =
                currentElement && currentElement.data.submodelItems
                    ? filterConnectionsByIds(currentElement.data.submodelItems?.wires || [])
                    : [];

            const setFilteredConnections = (mode: TWorkspaceMode) => {
                switch (mode) {
                    case WorkspaceModes.SUBMODEL:
                    case WorkspaceModes.FSM_EDITOR:
                        return connectionsFilteredSubmodel;
                    case WorkspaceModes.GROUP:
                        return connectionsFilteredGroup;
                    default:
                        return connectionsFilteredMain;
                }
            };

            const connectionsFiltered = setFilteredConnections(mode);

            nodes = state.schema.selectedItems.elements;
            connections = ids.length ? connectionsFiltered : [];
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                selectedItems: {
                    elements: nodes,
                    wires: connections,
                },
            },
        };
    },
    setItemsForSubmodel: (state: IWorkspaceState, action: PayloadAction<{ ids: string[]; type: TSchemaItemType }>) => {
        const { ids, type } = action.payload;
        const { mode, elementId } = state.meta;
        let nodes: TSchemaNode[] = [];
        let connections: TSchemaConnection[] = [];

        if (type === SchemaItemTypes.NODE) {
            const nodesFilteredMain = state.schema.schemaItems.elements.filter((node: TSchemaNode) =>
                ids.includes(node.id)
            );
            const currentElement = state.schema.schemaItems.elements.find((el: TSchemaNode) => el.id === elementId);

            const nodesFilteredSubmodel =
                currentElement && currentElement.data.submodelItems
                    ? currentElement.data.submodelItems.elements.filter((node: TSchemaNode) => ids.includes(node.id))
                    : [];
            const nodesFiltered = mode === WorkspaceModes.SUBMODEL ? nodesFilteredSubmodel : nodesFilteredMain;

            nodes = ids.length ? nodesFiltered : [];
            connections = state.schema.selectedItems.wires;
        } else if (type === SchemaItemTypes.CONNECTION) {
            const connectionsFiltered = state.schema.schemaItems.wires.filter((connection: TSchemaConnection) =>
                ids.includes(connection.id)
            );
            nodes = state.schema.selectedItems.elements;
            connections = ids.length ? connectionsFiltered : [];
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                itemsForSubmodel: {
                    elements: nodes,
                    wires: connections,
                },
            },
        };
    },
    addElementsForSubmodel: (state: IWorkspaceState, action: PayloadAction<TSchemaNode[]>) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                itemsForSubmodel: {
                    ...state.schema.itemsForSubmodel,
                    elements: [...state.schema.itemsForSubmodel.elements, ...action.payload],
                },
            },
        };
    },
    selectAllItems: (state: IWorkspaceState) => {
        const metaElementId = state.meta.elementId;
        const mode = state.meta.mode;
        const groups = state.schema.schemaItems.groups || [];
        if (mode === WorkspaceModes.FSM_EDITOR) {
            const submodelItems = state.schema.schemaItems.elements.find((el) => el.id === metaElementId)?.data
                .submodelItems || { elements: [], wires: [] };
            return {
                ...state,
                schema: {
                    ...state.schema,
                    selectedItems: {
                        elements: [...submodelItems.elements],
                        wires: [...submodelItems.wires],
                    },
                },
            };
        }
        if (metaElementId === null) {
            return {
                ...state,
                schema: {
                    ...state.schema,
                    selectedItems: {
                        elements: [...state.schema.schemaItems.elements],
                        wires: [...state.schema.schemaItems.wires],
                    },
                },
            };
        } else {
            const currentGroup = groups.find((group) => group.id.toString() === metaElementId);
            if (!currentGroup) {
                return state;
            }
            return {
                ...state,
                schema: {
                    ...state.schema,
                    selectedItems: {
                        elements: [...currentGroup.elements],
                        wires: [...currentGroup.wires],
                    },
                },
            };
        }
    },
    clearSelectedItems: (state: IWorkspaceState) => ({
        ...state,
        schema: {
            ...state.schema,
            selectedItems: initialState.selectedItems,
        },
    }),
    setSelectedGroup: (
        state: IWorkspaceState,
        action: PayloadAction<{ nodes: TSchemaNode[]; connections: TSchemaConnection[] }>
    ) => ({
        ...state,
        schema: {
            ...state.schema,
            selectedItems: {
                ...state.schema.selectedItems,
                elements: action.payload.nodes,
                wires: action.payload.connections,
            },
        },
    }),
};
