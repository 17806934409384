import styled from 'styled-components';

export const SLabel = styled.div`
    position: absolute;
    border-radius: 2px;
    display: inline-block;
    background: #fff;
    font-size: 10px;
    padding: 2px 4px;
    color: var(--ui-alert);
    z-index: 10;
`;
