import { ElemProps, TSchemaNode, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { TInputVariant, uiColors } from '@repeat/ui-kit';

import { generateTitleByAlphabet } from '../helpers';

export const CSV_DELIMITER = ';';

export const IS_SHOW_OPTIONS_SAVE_BUTTON = false;

export const IS_UNDO_REDO_USE_LOCAL_STORE = false;

export const TREND_CHART_INTERVAL_OPTION = 5;

export const getStylesByVariant = (variant: TInputVariant) => {
    switch (variant) {
        case 'success':
            return {
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px !important',
                    borderColor: `${uiColors.green} !important`,
                },
            };

        case 'warning':
            return {
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px !important',
                    borderColor: `${uiColors.yellow} !important`,
                },
            };
        case 'default':
        default:
            return {
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px !important',
                    borderColor: `rgba(49, 49, 49, 20%) !important`,
                },
            };
    }
};

export const MAX_CHART_HEIGHT = 330;

export const SCREENSHOT_OPTIONS = (SSTarget: HTMLElement) => ({
    width: SSTarget.clientWidth,
    height: SSTarget.clientHeight,
    quality: 0.5,
    backgroundColor: '#eee',
});

export const setWorkspaceMode = (mode: string, previousMode?: string | null) => {
    localStorage.setItem('workspaceMode', previousMode || mode);
    localStorage.setItem('previousMode', previousMode || mode);
};
export const getWorkspaceMode = () => {
    const mode = localStorage.getItem('workspaceMode') as TWorkspaceMode;
    const prevMode = localStorage.getItem('previousMode') as TWorkspaceMode;

    if (mode === WorkspaceModes.DEMO || prevMode === WorkspaceModes.DEMO) {
        setWorkspaceMode(WorkspaceModes.DEMO, WorkspaceModes.DEMO);
        return WorkspaceModes.DEMO;
    }

    setWorkspaceMode(WorkspaceModes.MAIN, WorkspaceModes.MAIN);
    return WorkspaceModes.MAIN;
};

export const getUsedTitles = (elements: TSchemaNode[]) =>
    elements
        ?.map((el) => el.data.elemProps)
        .flat()
        .filter((p: ElemProps) => p.name === 'title')
        .map((p: ElemProps) => {
            const match = p.value.toString().match(/\s(.+)/);
            return match ? match[1] : '';
        }) || [];

export const setFSMStateTitle = (elements: TSchemaNode[], elementProperties: ElemProps[]) => {
    const usedTitles = getUsedTitles(elements);
    const title = generateTitleByAlphabet(usedTitles);

    return elementProperties.map((property: ElemProps) => {
        if (property.name === 'title') {
            return {
                ...property,
                value: `Title ${title}`,
            };
        }
        return property;
    });
};
