import type { FC, PropsWithChildren } from 'react';
import { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SControlsPanel } from '@components/ReactFlow/SComponents/SReactFlow';
import { messages } from '@components/ReactFlow/translation';
import { useReactFlow, useStore, useStoreApi } from '@reactflow/core';
import cc from 'classcat';

import { useAppSelector } from '@repeat/hooks';
import { WorkspaceModes } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Tooltip, TooltipPosition } from '@repeat/ui-kit';

import ControlButton from './ControlButton';
import FitViewIcon from './Icons/FitView';
import LockIcon from './Icons/Lock';
import MinusIcon from './Icons/Minus';
import PlusIcon from './Icons/Plus';
import UnlockIcon from './Icons/Unlock';

import type { ControlProps, ReactFlowState } from './types';

const isInteractiveSelector = (s: ReactFlowState) => s.nodesDraggable && s.nodesConnectable && s.elementsSelectable;

const Controls: FC<PropsWithChildren<ControlProps>> = ({
    style,
    showZoom = true,
    showFitView = true,
    showInteractive = true,
    fitViewOptions,
    onZoomIn,
    onZoomOut,
    onFitView,
    onInteractiveChange,
    className,
    children,
    position = 'bottom-left',
}) => {
    const intl = useIntl();

    const store = useStoreApi();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const isInteractive = useStore(isInteractiveSelector);
    const { zoomIn, zoomOut, fitView } = useReactFlow();

    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const { previousMode, isUserBlockEditor } = useAppSelector((state) => state.workspace.meta);

    const userCannotUnlockCanvas =
        (workspaceMode === WorkspaceModes.GROUP && !isUserBlockEditor) ||
        workspaceMode === WorkspaceModes.PREVIEW ||
        workspaceMode === WorkspaceModes.DEMO ||
        workspaceMode === WorkspaceModes.SUBMODEL ||
        previousMode === WorkspaceModes.DEMO;

    useEffect(() => {
        setIsVisible(true);
    }, []);

    if (!isVisible) {
        return null;
    }

    const onZoomInHandler = () => {
        zoomIn();
        onZoomIn?.();
    };

    const onZoomOutHandler = () => {
        zoomOut();
        onZoomOut?.();
    };

    const onFitViewHandler = () => {
        fitView(fitViewOptions);
        onFitView?.();
    };

    const onToggleInteractivity = () => {
        store.setState({
            nodesDraggable: !isInteractive,
            nodesConnectable: !isInteractive,
            elementsSelectable: !isInteractive,
        });

        onInteractiveChange?.(!isInteractive);
    };

    return (
        <SControlsPanel className={cc(['react-flow__controls', className])} position={position} style={style}>
            {showZoom && (
                <>
                    <Tooltip
                        text={intl.formatMessage(messages[TranslationKey['ZOOM_IN']])}
                        position={TooltipPosition.RIGHT}
                    >
                        <ControlButton onClick={onZoomInHandler} className='react-flow__controls-zoomin'>
                            <PlusIcon />
                        </ControlButton>
                    </Tooltip>
                    <Tooltip
                        text={intl.formatMessage(messages[TranslationKey['ZOOM_OUT']])}
                        position={TooltipPosition.RIGHT}
                    >
                        <ControlButton onClick={onZoomOutHandler} className='react-flow__controls-zoomout'>
                            <MinusIcon />
                        </ControlButton>
                    </Tooltip>
                </>
            )}
            {showFitView && (
                <Tooltip
                    text={intl.formatMessage(messages[TranslationKey['FIT_VIEW']])}
                    position={TooltipPosition.RIGHT}
                >
                    <ControlButton className='react-flow__controls-fitview' onClick={onFitViewHandler}>
                        <FitViewIcon />
                    </ControlButton>
                </Tooltip>
            )}
            {showInteractive && (
                <Tooltip
                    text={
                        isInteractive
                            ? intl.formatMessage(messages[TranslationKey['LOCK_CANVAS']])
                            : intl.formatMessage(messages[TranslationKey['UNLOCK_CANVAS']])
                    }
                    position={TooltipPosition.RIGHT}
                >
                    {userCannotUnlockCanvas ? (
                        <ControlButton
                            className='react-flow__controls-interactive'
                            onClick={onToggleInteractivity}
                            disabled
                        >
                            <LockIcon />
                        </ControlButton>
                    ) : (
                        <ControlButton className='react-flow__controls-interactive' onClick={onToggleInteractivity}>
                            {isInteractive ? <UnlockIcon /> : <LockIcon />}
                        </ControlButton>
                    )}
                </Tooltip>
            )}
            {children}
        </SControlsPanel>
    );
};

Controls.displayName = 'Controls';

export default memo(Controls);
