import { FC, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { EdgeLabelRenderer, getSmoothStepPath, Position, useReactFlow } from 'reactflow';

import { useReadOnlyMode } from 'apps/repeat/src/hooks/useReadOnlyMode';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { elementsSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { messages } from './../../../Visualization/translation';
import ClickableBaseEdge from './ClickableBaseEdge';
import { SLabel } from './SCustomEdge';

interface IEdge {
    id: string;
    sourceY: number;
    targetX: number;
    sourceX: number;
    targetY: number;
    targetPosition: Position;
    sourcePosition: Position;
    data?: any;
    selected?: boolean;
}

export const CustomEdge: FC<IEdge> = memo(
    ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data, selected }) => {
        const intl = useIntl();

        const dispatch = useAppDispatch();

        const reactFlowInstance = useReactFlow();

        const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
        const prevWorkspaceMode = useAppSelector((state) => state.workspace.meta.previousMode);
        const { isReadOnlyMode } = useReadOnlyMode(workspaceMode, prevWorkspaceMode as TWorkspaceMode);
        const groupId = useAppSelector((state) => state.workspace.meta.groupId);
        const metaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
        const isUserBlockEditor = useAppSelector((state) => state.workspace.meta.isUserBlockEditor);

        const isTotalReadMode =
            !isReadOnlyMode ||
            (workspaceMode === WorkspaceModes.GROUP && groupId && metaUserBlockId !== null && !isUserBlockEditor);

        const currentSubmodelItems = useAppSelector(workspaceSelectors.currentSubmodelItems);

        const mainWires = useAppSelector(elementsSelectors.getWires);
        const wires =
            workspaceMode === WorkspaceModes.SUBMODEL || workspaceMode === WorkspaceModes.GROUP
                ? currentSubmodelItems?.wires || []
                : mainWires;

        const [wire, setWire] = useState(wires.find((w) => w.id === id));
        const isValidConnection = wire?.data.isValidConnection ?? true;

        const [positionHandlers, setPositionHandlers] = useState(data?.positionHandlers ?? []);

        useEffect(() => {
            setPositionHandlers(data?.positionHandlers ?? []);
        }, [data?.positionHandlers]);

        if (!wire) {
            return null;
        }

        const handleChangeHandlers = (handlers: any) => {
            dispatch(
                workspaceActions.setConnectionPositionHandlers({
                    id,
                    value: handlers,
                })
            );
        };

        const edgeSegmentsCount = positionHandlers.length + 1;
        const edgeSegmentsArray = [];

        let startLabelX, startLabelY;
        const labelSegmentNumber = Math.floor(positionHandlers.length / 2);

        // calculate the origin and destination of all the segments
        const defaultOffset = 0;
        for (let i = 0; i < edgeSegmentsCount; i++) {
            let segmentSourceX, segmentSourceY, segmentTargetX, segmentTargetY;
            let offset: number | undefined = 0;

            if (i === 0) {
                segmentSourceX = sourceX;
                segmentSourceY = sourceY;
            } else {
                const handler = positionHandlers[i - 1];
                segmentSourceX = handler.x;
                segmentSourceY = handler.y;

                if (
                    Math.abs(segmentSourceX - sourceX) < defaultOffset ||
                    Math.abs(segmentSourceY - sourceY) < defaultOffset
                ) {
                    offset = defaultOffset;
                }
            }

            if (i === edgeSegmentsCount - 1) {
                segmentTargetX = targetX;
                segmentTargetY = targetY;
            } else {
                const handler = positionHandlers[i];
                segmentTargetX = handler.x;
                segmentTargetY = handler.y;

                if (
                    Math.abs(segmentTargetX - targetX) < defaultOffset ||
                    Math.abs(segmentTargetY - targetY) < defaultOffset
                ) {
                    offset = defaultOffset;
                }
            }

            const [edgePath, labelX, labelY] = getSmoothStepPath({
                sourceX: segmentSourceX,
                sourceY: segmentSourceY,
                sourcePosition: i === 0 ? sourcePosition : Position.Right,
                targetPosition: i === edgeSegmentsCount - 1 ? targetPosition : Position.Left,
                targetX: segmentTargetX,
                targetY: segmentTargetY,
                offset,
                borderRadius: 2,
            });
            edgeSegmentsArray.push({ edgePath, labelX, labelY });

            if (i === labelSegmentNumber) {
                startLabelX = labelX;
                startLabelY = labelY;
            }
        }

        return (
            <>
                {edgeSegmentsArray.map(({ edgePath, labelX, labelY }, index) => (
                    <ClickableBaseEdge
                        onClick={(event: any) => {
                            if ((selected && isTotalReadMode) || !selected) {
                                return;
                            }

                            const position = reactFlowInstance.screenToFlowPosition({
                                x: event.clientX,
                                y: event.clientY,
                            });

                            const newPositionHandlers = [...positionHandlers];
                            newPositionHandlers.splice(index, 0, {
                                x: position.x,
                                y: position.y,
                            });

                            handleChangeHandlers(newPositionHandlers);
                        }}
                        key={`edge${id}_segment${index}`}
                        path={edgePath}
                        style={{
                            stroke: isValidConnection ? 'var(--ui-text)' : 'var(--ui-alert)',
                            strokeWidth: selected === true && !isTotalReadMode ? '2px' : '1px',
                        }}
                    />
                ))}
                {positionHandlers.map(
                    ({ x, y, active }: { x: number; y: number; active: number }, handlerIndex: number) => (
                        <EdgeLabelRenderer key={`edge${id}_handler${handlerIndex}`}>
                            <div
                                className={`nopan positionHandlerContainer ${
                                    selected === true && !isTotalReadMode ? 'selected-edge' : 'default-edge'
                                }`}
                                style={{
                                    transform: `translate(-50%, -50%) translate(${x}px,${y}px)`,
                                }}
                            >
                                <div
                                    className={`positionHandlerEventContainer ${active} ${
                                        `${active ?? -1}` !== '-1' ? 'active' : ''
                                    }`}
                                    data-active={active ?? -1}
                                    // mouse move is used to move the handler when its been mousedowned on
                                    onMouseMove={(event: any) => {
                                        const activeEdge = parseInt(event.target.dataset.active ?? -1);
                                        if (activeEdge === -1) {
                                            return;
                                        }

                                        const position = reactFlowInstance.screenToFlowPosition({
                                            x: event.clientX,
                                            y: event.clientY,
                                        });

                                        const newPositionHandlers = positionHandlers.map(
                                            (handler: any, index: string) => {
                                                if (+index === +handlerIndex) {
                                                    return {
                                                        x: position.x,
                                                        y: position.y,
                                                        active: activeEdge,
                                                    };
                                                }
                                                return { ...handler };
                                            }
                                        );

                                        setPositionHandlers(newPositionHandlers);
                                    }}
                                    // mouse up is used to release all the handlers
                                    onMouseUp={() => {
                                        const newPositionHandlers = positionHandlers.map(
                                            (handler: any, index: string) => ({
                                                ...handler,
                                                active: -1,
                                            })
                                        );

                                        setPositionHandlers(newPositionHandlers);
                                        handleChangeHandlers(newPositionHandlers);
                                    }}
                                >
                                    <button
                                        className='positionHandler'
                                        data-active={active ?? -1}
                                        // mouse down is used to activate the handler
                                        onMouseDown={() => {
                                            if (isTotalReadMode) {
                                                return;
                                            }

                                            const newPositionHandlers = positionHandlers.map(
                                                (handler: any, index: string) => {
                                                    if (+index === +handlerIndex) {
                                                        return {
                                                            ...handler,
                                                            active: 1,
                                                        };
                                                    }
                                                    return { ...handler };
                                                }
                                            );

                                            setPositionHandlers(newPositionHandlers);
                                        }}
                                        // right click is used to delete the handler
                                        onContextMenu={(event) => {
                                            event.preventDefault();

                                            if (isTotalReadMode) {
                                                return;
                                            }

                                            const newPositionHandlers = [...positionHandlers];
                                            newPositionHandlers.splice(handlerIndex, 1);

                                            handleChangeHandlers(newPositionHandlers);
                                        }}
                                    ></button>
                                </div>
                            </div>
                        </EdgeLabelRenderer>
                    )
                )}
                {!isValidConnection && (
                    <EdgeLabelRenderer>
                        <SLabel
                            style={{
                                transform: `translate(-50%, -50%) translate(${startLabelX}px,${startLabelY}px)`,
                                pointerEvents: 'all',
                            }}
                            className='nodrag nopan'
                        >
                            {intl.formatMessage(messages[TranslationKey.SCHEMA_INVALID_CONNECTION])}
                        </SLabel>
                    </EdgeLabelRenderer>
                )}
            </>
        );
    }
);
