import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from '@repeat/hooks';
import { ElemParams, SchemaSelectionModes, WorkspaceModes } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { ShowMore, uiColors } from '@repeat/ui-kit';

import { ItemParameter } from '../ItemOptions/ItemParameter';
import { messages } from '../translation';

export const ElementParameters: FC<{ mode?: SchemaSelectionModes }> = ({ mode }) => {
    const intl = useIntl();

    const currentNodeWithParameters = useAppSelector(workspaceSelectors.currentNodeParameters, shallowEqual);
    const currentSelectedNode = useAppSelector(workspaceSelectors.selectedItems).elements[0];
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const externalParameters = useAppSelector((state) => state.workspace.schema.externalInfo?.parameters);

    const { elemParams, parametersToDisplay } = currentNodeWithParameters;
    const elementParams =
        mode === SchemaSelectionModes.PROJECT && workspaceMode === WorkspaceModes.MAIN && externalParameters
            ? externalParameters
            : elemParams;

    if (elementParams) {
        let parametersResult: ElemParams[] = [];
        if (parametersToDisplay === null || parametersToDisplay === undefined) {
            parametersResult = elementParams;
        } else if (parametersToDisplay && parametersToDisplay.length === 0) {
            parametersResult = [];
        } else if (parametersToDisplay?.length !== 0 && parametersToDisplay) {
            parametersResult = parametersToDisplay.map((str) =>
                elementParams.find((param) => param.name.includes(str))
            ) as ElemParams[];
        }
        if (!currentSelectedNode?.data?.isNeedToUpdate)
            parametersResult = parametersResult.filter(
                (param) => param && (param?.isVisible || !('isVisible' in param))
            );

        const noParametersMessage =
            mode === SchemaSelectionModes.PROJECT
                ? intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS_NOT_DEFINED])
                : intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND]);
        return (
            <ShowMore title={intl.formatMessage(messages[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS])}>
                {parametersResult?.length !== 0 && parametersResult !== null ? (
                    parametersResult?.map((parameter: ElemParams, index: number) => (
                        <ItemParameter
                            key={index}
                            parameter={parameter}
                            isManaged={mode === SchemaSelectionModes.PROJECT}
                        />
                    ))
                ) : (
                    <small style={{ color: `${uiColors.darkGrey}`, fontSize: '12px' }}>{noParametersMessage}</small>
                )}
            </ShowMore>
        );
    }
    return null;
};
