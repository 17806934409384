import { ElemParams, ElemProps, TModelElement, TSchemaFSMConnection, TSchemaNode } from '@repeat/models';

interface IFSMState {
    name: string;
    action: string;
}
interface IFSMTransition {
    from: string;
    to: string;
    priority: string;
    condition: string;
    action: string;
}
export interface IFSMVariable {
    type: string;
    name: string;
    typeJava: string;
    value: string;
}
export interface IFSMModelVariable {
    typeIO: string;
    typeJava: string;
    name: string;
    port: string;
}

export interface IFSMModel {
    states: IFSMState[];
    transitions: IFSMTransition[];
    variables: IFSMModelVariable[];
    initialTransition: IFSMTransition | null;
}

const variableTypeToModel = (type: string) => {
    let modelType = 'undefined';

    switch (type) {
        case 'in':
            modelType = 'input';
            break;
        case 'out':
            modelType = 'output';
            break;
        case 'internal':
            modelType = type;
            break;
        default:
            break;
    }

    return modelType;
};

export const prepareStateMachineModel = (
    elements: TSchemaNode[],
    wires: TSchemaFSMConnection[],
    variables: IFSMVariable[]
): IFSMModel => {
    let initialTransition: IFSMTransition | null = null;
    let states: IFSMState[] = [];
    let transitions: IFSMTransition[] = [];
    let preparedVariables: IFSMModelVariable[] = [];

    let startBlock: TSchemaNode | null = null;

    const blockIdToNameMap = new Map<string, { name: string; outTransitionsCounter: number }>();

    // prepare states
    elements.forEach((node: TSchemaNode) => {
        const block = node.data;

        if (block.type === 'fsm-start') {
            startBlock = node;
            return;
        }

        const name = (block.elemProps.find((property: ElemProps) => property.name === 'title')?.value as string) ?? '';

        blockIdToNameMap.set(node.id, { name, outTransitionsCounter: 0 });

        states = [
            ...states,
            {
                name,
                action:
                    (block.elemProps.find((property: ElemProps) => property.name === 'action')?.value as string) ?? '',
            },
        ];
    });

    // prepare transitions
    wires.forEach((connection: TSchemaFSMConnection) => {
        if (startBlock && connection.source === startBlock.id) {
            initialTransition = {
                from: '',
                to: blockIdToNameMap.get(connection.target)?.name ?? '',
                priority: '0',
                condition: connection.data?.condition ?? '',
                action: connection.data?.action ?? '',
            };
            return;
        }

        transitions = [
            ...transitions,
            {
                from: blockIdToNameMap.get(connection.source)?.name ?? '',
                to: blockIdToNameMap.get(connection.target)?.name ?? '',
                priority: blockIdToNameMap.get(connection.source)?.outTransitionsCounter.toString() ?? '0',
                condition: connection.data?.condition ?? '',
                action: connection.data?.action ?? '',
            },
        ];

        const fromBlockMapItem = blockIdToNameMap.get(connection.source);
        if (fromBlockMapItem) {
            const counter = fromBlockMapItem.outTransitionsCounter + 1;
            blockIdToNameMap.set(connection.source, {
                ...fromBlockMapItem,
                outTransitionsCounter: counter,
            });
        }
    });

    // prepare variables
    const portsCounter: { [key: string]: number } = {
        input: 0,
        output: 0,
    };
    variables.forEach((variable: IFSMVariable) => {
        const modelType = variableTypeToModel(variable.type);
        const isIOVariable = portsCounter[modelType] !== undefined;

        preparedVariables = [
            ...preparedVariables,
            {
                typeIO: modelType,
                typeJava: variable.typeJava,
                name: variable.name,
                port: isIOVariable ? portsCounter[modelType].toString() : '',
            },
        ];

        if (isIOVariable) {
            portsCounter[modelType]++;
        }
    });

    return {
        states,
        transitions,
        variables: preparedVariables,
        initialTransition,
    };
};

export const prepareFSMBlockData = (node: TSchemaNode) => {
    const element = node.data;
    let modelElement = {
        id: element.id,
        type: element.type,
        elemParams: element.elemParams.map((elementParam: ElemParams) => ({
            ...elementParam,
            modelName: '',
        })),
        elemProps: element.elemProps,
        position: node.position,
    } as TModelElement;

    const jsonCodePropertyIndex = element.elemProps.findIndex((property: ElemProps) => property.name === 'jsonCode');
    if (jsonCodePropertyIndex >= 0) {
        const jsonCodeProperty = element.elemProps[jsonCodePropertyIndex];
        const fsmItems = element?.submodelItems ?? {
            elements: [],
            wires: [],
        };
        const variablesProperty = element.elemProps.find((property: ElemProps) => property.name === 'variables');
        const variables =
            variablesProperty && (variablesProperty.value as string).length > 0
                ? JSON.parse(variablesProperty.value as string)
                : [];

        const fsmModel = JSON.stringify(prepareStateMachineModel(fsmItems.elements, fsmItems.wires, variables));

        modelElement = {
            ...modelElement,
            elemProps: [
                ...element.elemProps.slice(0, jsonCodePropertyIndex),
                {
                    ...jsonCodeProperty,
                    value: fsmModel,
                },
                ...element.elemProps.slice(jsonCodePropertyIndex + 1),
            ],
        };
    }
    return modelElement;
};

export const prepareModelBlockData = (node: TSchemaNode) => {
    const element = node.data;
    let modelElement = {
        id: element.id,
        type: element.type,
        elemParams: element.elemParams.map((elementParam: ElemParams) => ({
            ...elementParam,
            modelName: '',
        })),
        elemProps: element.elemProps,
        position: node.position,
    } as TModelElement;

    if (element.proxyMap) {
        modelElement = { ...modelElement, proxyMap: element.proxyMap };
    }
    if (element.uuid) {
        modelElement = { ...modelElement, uuid: element.uuid };
    }
    if (element.blockId) {
        modelElement = { ...modelElement, blockId: element.blockId };
    }
    if (element.type === 'StateMachine') {
        modelElement = prepareFSMBlockData(node);
    }

    return modelElement;
};

export const prepareBlockData = (node: TSchemaNode): TSchemaNode => {
    const { diff, ...nodeData } = node.data;
    const element = nodeData;
    if (element.type === 'project' && 'submodelItems' in node.data) {
        const { submodelItems, ...rest } = element;
        return { ...node, data: rest };
    }
    if (element.type === 'StateMachine') {
        const modelElement = prepareFSMBlockData(node);

        return {
            ...node,
            data: { ...node.data, ...modelElement },
        };
    }

    return { ...node, data: nodeData };
};
