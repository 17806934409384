import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { loadUserBlock } from 'libs/repeat/store/src/lib/slices/workspace/userBlocks/userBlocksSlice';

import { ApplicationActions } from '@repeat/common-slices';
import { SAVE_NEW_PROJECT_FORM } from '@repeat/constants';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Statuses, WorkspaceModes } from '@repeat/models';
import { getProjects, openNewProject, saveProject, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { useProjectScreenshot } from '../Project/hooks/useProjectScreenshot';
import { projectMessages as messages } from '../Project/translations';

export const ProjectConfirmSavingModal: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { projectId } = useProjectId();
    const { handleScreenShot } = useProjectScreenshot();

    const currentSubmodelProject = useAppSelector(workspaceSelectors.currentSubmodelProject);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const currentSubmodelProjectName = currentSubmodelProject?.projectName || '';
    const currentSubmodelProjectId = currentSubmodelProject?.projectId || '';
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const currentUserBlockName = useAppSelector(
        (state) =>
            state.workspace.libraryItems.items.find((item) => item.blockId === workspaceMetaUserBlockId)?.name || ''
    );
    const libraryType = useAppSelector(workspaceSelectors.libraryType);
    const solverType = useAppSelector(workspaceSelectors.solverType);
    const projectsState = useAppSelector((state) => state.projects);

    const currentProjectName = localStorage.getItem('currentProjectName') || '';

    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;

    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_CONFIRM_SAVING }));
    };

    const handleSave = async () => {
        if (projectId && solverType && libraryType) {
            await dispatch(saveProject(projectId, libraryType, solverType));
            handleScreenShot(projectId);
        }
        if (workspaceMetaUserBlockId) {
            dispatch(loadUserBlock(workspaceMetaUserBlockId, currentUserBlockName));
        } else {
            dispatch(openNewProject(currentSubmodelProjectId, currentSubmodelProjectName)).then(() =>
                dispatch(workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.MAIN, prevMode: null }))
            );
        }
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_CONFIRM_SAVING }));
    };

    const handleNotSave = () => {
        if (workspaceMetaUserBlockId) {
            dispatch(loadUserBlock(workspaceMetaUserBlockId, currentUserBlockName));
        } else {
            dispatch(openNewProject(currentSubmodelProjectId, currentSubmodelProjectName)).then(() =>
                dispatch(workspaceActions.changeWorkspaceMode({ mode: WorkspaceModes.MAIN, prevMode: null }))
            );
        }
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_CONFIRM_SAVING }));
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button form={SAVE_NEW_PROJECT_FORM} type='submit' onClick={handleSave}>
                {intl.formatMessage(messages[TranslationKey.SAVE])}
            </Button>
            <Button variant='outlined' form={SAVE_NEW_PROJECT_FORM} type='submit' onClick={handleNotSave}>
                {intl.formatMessage(messages[TranslationKey.DO_NOT_SAVE])}
            </Button>
        </>
    );

    const message =
        workspaceMode === WorkspaceModes.GROUP ||
        workspaceMode === WorkspaceModes.DEMO ||
        workspaceMode === WorkspaceModes.PREVIEW
            ? intl.formatMessage(messages[TranslationKey.WORKSPACE_USER_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE]) // TODO add user block name
            : intl.formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE], {
                  subProject: <b>{currentSubmodelProjectName}</b>,
              });

    return (
        <Modal
            id={ModalTypes.PROJECT_EDIT}
            title={intl.formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='small'
            footer={<FooterButtons />}
        >
            <div>
                <p style={{ textIndent: '16px' }}>{message}</p>
                <br />
                <p style={{ textIndent: '16px' }}>
                    {intl.formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_CONFIRM_SAVING_PROJECT], {
                        mainProject: <b>{currentProjectName}</b>,
                    })}
                </p>
            </div>
        </Modal>
    );
};
