import { TSchemaConnection, TSchemaGroup, TSchemaNode } from '@repeat/models';

export const updateSchemaElements = (
    elements: TSchemaNode[],
    id: number,
    updateFn: (el: TSchemaNode) => TSchemaNode
) => {
    return elements.map((el) => (el.data.id === id ? updateFn(el) : el));
};

export const updateSchemaGroupElements = (
    groups: TSchemaGroup[] | undefined,
    groupId: string,
    id: number,
    updateFn: (el: TSchemaNode) => TSchemaNode
) => {
    return groups?.map((group) => {
        if (group.id.toString() === groupId) {
            const updatedElements = updateSchemaElements(group.elements, id, updateFn);
            return {
                ...group,
                elements: updatedElements,
            };
        }
        return group;
    });
};

export const updateSchemaConnections = (
    connections: TSchemaConnection[],
    id: string,
    updateFn: (connection: TSchemaConnection) => TSchemaConnection
) => {
    return connections.map((connection) => (connection.id === id ? updateFn(connection) : connection));
};

export const updateSchemaGroupConnections = (
    groups: TSchemaGroup[] | undefined,
    groupId: string,
    id: string,
    updateFn: (connection: TSchemaConnection) => TSchemaConnection
) => {
    return groups?.map((group) => {
        if (group.id.toString() === groupId) {
            const updatedConnections = updateSchemaConnections(group.wires, id, updateFn);
            return {
                ...group,
                wires: updatedConnections,
            };
        }
        return group;
    });
};
