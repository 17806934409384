import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ElemParams, WorkspaceModes } from '@repeat/models';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, ShowMore, uiColors } from '@repeat/ui-kit';

import { SBlock, SBlockTitle, SBlockTop } from './SParametersSettings';

import { ItemParameter } from '../ItemOptions/ItemParameter';
import { messages } from '../translation';

export const ParametersSettings: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const { mode, elementId, previousMode, isUserBlockEditor, userBlockId } = useAppSelector(
        (state) => state.workspace.meta
    );

    const currentNodeWithParameters = useAppSelector(workspaceSelectors.currentNodeParameters, shallowEqual);

    const { elemParams: parameters, availablePorts } = currentNodeWithParameters;

    const inputParameters = parameters.filter((p) => p.name.includes('in'));
    const outputParameters = parameters.filter((p) => p.name.includes('out'));

    const Message = () => (
        <small style={{ color: `${uiColors.darkGrey}`, fontSize: '12px' }}>
            {intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND])}
        </small>
    );

    return (
        <ShowMore title={intl.formatMessage(messages[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS])}>
            <SBlock>
                <SBlockTop>
                    <SBlockTitle>
                        {intl.formatMessage(messages[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_INPUTS])}
                    </SBlockTitle>
                    {mode === WorkspaceModes.CODE_EDITOR &&
                        previousMode !== WorkspaceModes.DEMO &&
                        !(mode === WorkspaceModes.CODE_EDITOR && !isUserBlockEditor && !!userBlockId) && (
                            <IconButton
                                noHover
                                fill={uiColors.green}
                                name='plus'
                                onClick={() => {
                                    elementId && dispatch(workspaceActions.addParameter({ id: elementId, type: 'in' }));
                                }}
                            />
                        )}
                </SBlockTop>
                {inputParameters.length !== 0 ? (
                    inputParameters?.map((parameter: ElemParams, index: number) => (
                        <ItemParameter
                            key={`${parameter.name}-${index}`}
                            parameter={parameter}
                            isManaged={mode === WorkspaceModes.CODE_EDITOR}
                            isConnected={availablePorts.find((p) => p.name === parameter.name)?.isConnected}
                        />
                    ))
                ) : (
                    <Message />
                )}
            </SBlock>
            <SBlock>
                <SBlockTop>
                    <SBlockTitle>
                        {intl.formatMessage(messages[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_OUTPUTS])}
                    </SBlockTitle>
                    {mode === WorkspaceModes.CODE_EDITOR &&
                        previousMode !== WorkspaceModes.DEMO &&
                        !(mode === WorkspaceModes.CODE_EDITOR && !isUserBlockEditor && !!userBlockId) && (
                            <IconButton
                                noHover
                                fill={uiColors.green}
                                name='plus'
                                onClick={() => {
                                    elementId &&
                                        dispatch(workspaceActions.addParameter({ id: elementId, type: 'out' }));
                                }}
                            />
                        )}
                </SBlockTop>
                {outputParameters.length !== 0 ? (
                    outputParameters?.map((parameter: ElemParams) => (
                        <ItemParameter
                            key={parameter.name}
                            parameter={parameter}
                            isManaged={mode === WorkspaceModes.CODE_EDITOR}
                            isConnected={availablePorts.find((p) => p.name === parameter.name)?.isConnected}
                        />
                    ))
                ) : (
                    <Message />
                )}
            </SBlock>
        </ShowMore>
    );
};
