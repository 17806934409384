import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useCopyTextToClipboard, useUserRole } from '@repeat/common-hooks';
import { ApplicationActions } from '@repeat/common-slices';
import { getCookie } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, NotificationTypes, Project, TModal } from '@repeat/models';
import {
    appUserSelectors,
    createFolder,
    deleteFolder,
    duplicateProject,
    editFolder,
    exportProject,
    getFavProjects,
    getFilesAndFolders,
    importProject,
    moveFileAndFolder,
    openProject,
    projectsSelectors,
    updateProject,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { FileManager, TFileManagerElement, TOnMovePayload } from '@repeat/ui-kit';

import { useFileManagerTranslate } from './hooks/useFileManagerTranslate';
import { useProjectDemo } from './hooks/useProjectDemo';
import { useProjectExport } from './hooks/useProjectExport';
import { useFavorites } from './hooks/useProjectFavorites';
import { SProjectWrapper } from './styled/SProjectTable';
import { projectMessages as messages } from './translations';

const { API_URL: baseURL } = environment;

export const Projects: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const projects = useAppSelector(projectsSelectors.projectsItems);
    const user = useAppSelector(appUserSelectors.currentUser);

    const favoriteProjects = useAppSelector((state) => {
        const favProjects = state.projects.favProjects.items;
        if (favProjects !== null && favProjects.length) {
            return favProjects.map((favProject) => favProject.projectId);
        }
        return [];
    });

    const fileManagerTranslate = useFileManagerTranslate();

    const fileManagerStore = useAppSelector((state) => state.fileManager);

    const { exportLinkRef, download } = useProjectExport();

    const { handleAddFavorites } = useFavorites(favoriteProjects);

    const { handleCopyEvent } = useCopyTextToClipboard();

    const { handleAddToDemo } = useProjectDemo();

    const { isAdmin } = useUserRole(user);

    useEffect(() => {
        dispatch(getFavProjects());
    }, []);

    const handleOpenProject = useCallback(async (projectId: number) => {
        await dispatch(openProject(projectId, navigate));
    }, []);

    const handleEditProject = async (projectId: number, name: string) => {
        const data = projects.find((project) => project.projectId === projectId) as Project;
        const updatedData = { ...data, projectName: name };
        await dispatch(updateProject({ ...updatedData, projectId }));
    };

    const handleExportProject = (projectId: number) => {
        dispatch(exportProject({ projectId, onDownload: handleDownload }));
    };

    const handleDownload = (localUrl: string, fileExtension: string, filename: string | null) => {
        if (filename) {
            download(localUrl, filename);
        }
    };

    const handleCreateProjectClick = (folderId: number) => {
        localStorage.setItem('folderId', `${folderId}`);
        const modal: TModal = {
            type: ModalTypes.PROJECT_CREATE,
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const handleErrorOnCopyEvent = useCallback(() => {
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.WARNING,
                    message: TranslationKey.NOT_COPIED_TO_CLIPBOARD_PROJECT_ID,
                },
            })
        );
    }, []);

    const handleSuccessOnCopyEvent = useCallback(() => {
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: TranslationKey.COPIED_TO_CLIPBOARD,
                },
            })
        );
    }, []);

    const handleCopy = (id: number) => {
        const project = fileManagerStore.elements.find((project) => project.id === id) as TFileManagerElement;
        if (project && project.id) {
            handleCopyEvent(project.id.toString(), handleErrorOnCopyEvent, handleSuccessOnCopyEvent);
        }
    };

    const nameListCompare = useMemo(() => {
        return projects !== null
            ? projects
                  .filter((project: Project) => project?.projectName && typeof project?.projectName === 'string')
                  .map((project) => project.projectName.toLowerCase())
            : [];
    }, [projects]);

    return (
        <SProjectWrapper>
            <FileManager
                rules={{
                    manageDemo: isAdmin,
                    addToRoot: false,
                }}
                translations={{
                    ...fileManagerTranslate,
                    manageDemo: intl.formatMessage(messages[TranslationKey.PROJECT_ADD_TO_DEMO_DROPDOWN]),
                    addToRoot: intl.formatMessage(messages[TranslationKey.DEMO_PROJECT_DUPLICATE_DROPDOWN]),
                }}
                store={fileManagerStore}
                imageLoader={{
                    headers: { Authorization: `Bearer ${getCookie('accessToken')}` },
                    url: `${baseURL}/api/v1/project/preview`,
                    fallback: 'projectFile',
                    requestKey: 'projectId',
                }}
                nameListCompare={nameListCompare}
                favoriteList={[...favoriteProjects]}
                onGetData={(id: number) => dispatch(getFilesAndFolders(id))}
                onCreateFolder={(folderId: number, name: string) => dispatch(createFolder(folderId, name))}
                onEditFolder={(folderId: number, name: string) => dispatch(editFolder(folderId, name))}
                onEditFile={(projectId: number, name: string) => handleEditProject(projectId, name)}
                onGetFavoriteList={() => dispatch(getFavProjects())}
                onAddToFavorites={(id: number) => handleAddFavorites(id)}
                onDelete={(folders: TFileManagerElement[]) => dispatch(deleteFolder(folders))}
                onMove={(payload: TOnMovePayload) => dispatch(moveFileAndFolder(payload))}
                onImport={(file: File) => dispatch(importProject(file, navigate))}
                onCreateFile={(folderId: number) => handleCreateProjectClick(folderId)}
                onOpenFile={(projectId: number) => handleOpenProject(projectId)}
                onExport={(projectId: number) => handleExportProject(projectId)}
                onCopyId={(projectId: number) => handleCopy(projectId)}
                onDuplicate={(projectId: number) => dispatch(duplicateProject(projectId))}
                onManageDemo={(id: number) => handleAddToDemo(id)}
            />
            <a ref={exportLinkRef} target='_blank' rel='noreferrer noopener' href='/#' />
        </SProjectWrapper>
    );
};
