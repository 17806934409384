import { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Button from '@mui/material/Button';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { WorkspaceModes } from '@repeat/models';
import { workspaceActions } from '@repeat/store';
import { ru, TranslationKey } from '@repeat/translations';
import { Icon, ShowMore, uiColors } from '@repeat/ui-kit';

const messages = defineMessages({
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
});

export const ConnectionCurrent: FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();

    const workspaceMode = useAppSelector((state) => state.workspace.meta.mode);
    const prevWorkspaceMode = useAppSelector((state) => state.workspace.meta.previousMode);

    const deleteConnection = () => {
        dispatch(workspaceActions.deleteSchemaItems());
    };

    return (
        <ShowMore title={''}>
            {workspaceMode !== WorkspaceModes.PREVIEW &&
                workspaceMode !== WorkspaceModes.SUBMODEL &&
                workspaceMode !== WorkspaceModes.DEMO &&
                prevWorkspaceMode !== WorkspaceModes.DEMO && (
                    <Button
                        onClick={() => {
                            deleteConnection();
                        }}
                        type='button'
                    >
                        <Icon name='close' fill={uiColors.mainBlue} />
                        {formatMessage(messages[TranslationKey.DELETE])}
                    </Button>
                )}
        </ShowMore>
    );
};
