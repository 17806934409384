import { useCallback } from 'react';

import { css } from 'styled-components';

import { ElementNotificationTypes, LibraryTypes, TLibraryType } from '@repeat/models';
import { radius, uiColors } from '@repeat/ui-kit';

const LIBRARY_STYLES = {
    [LibraryTypes.COMMON]: ['#D7D7D7', '#838383'],
    [LibraryTypes.SIMULATION]: ['#D7D7D7', '#838383'],
    [LibraryTypes.ISOTHERMAL_HYDRAULICS]: ['#A6E4ED', '#025EA1'],
    [LibraryTypes.AUTO]: ['#f5aba7', '#ad1313'],
    [LibraryTypes.MECHANICS]: ['#b6d6b2', '#1d988c'],
    [LibraryTypes.ELECTRONICS]: ['#c1e0f4', '#6eaddf'],
    [LibraryTypes.ELECTROCITY]: ['#e9b073', '#b55b00'],
    [LibraryTypes.THERMOHYDRAULICS]: ['#e6ba61', '#b88652'],
    [LibraryTypes.HEAT_EXCHANGE]: ['#ffb96f', '#ee6423'],
    [LibraryTypes.PNEUMATICS]: ['#F0A9F7', '#C26DD1'],
    [LibraryTypes.ELECTRIC_DRIVE]: ['#B372C4', '#7C06A6'],
    cms: ['#9281F6', '#00007F'],
    [`${LibraryTypes.SYSTEM_DESIGN}`]: ['#9281F6', '#00007F'],
    [`${LibraryTypes.SYSTEM_DESIGN}_transport`]: ['#EDD872', '#EDAB20'],
    [`${LibraryTypes.SYSTEM_DESIGN}_cms`]: ['#9281F6', '#00007F'],
    [`${LibraryTypes.SYSTEM_DESIGN}_radioFrequencyAnalysis`]: ['#BAAEFF', '#3380EE'],
};

const oldBlocksGradientMap = new Map<string, string[]>([
    ['cmsFlow', ['#9281F6', '#00007F']],
    ['heatMetal', ['#9281F6', '#00007F']],
    ['batteryCell', ['#B372C4', '#7C06A6']],
    ['motorDrive', ['#c1e0f4', '#6eaddf']],
    ['simple4WheelDriveLine', ['#EDD872', '#EDAB20']],
    ['car4Wheels', ['#EDD872', '#EDAB20']],
    ['integrationStep', ['#D7D7D7', '#838383']],
    ['NodeTH', ['#e6ba61', '#b88652']],
    ['ReservoirTH', ['#e6ba61', '#b88652']],
    ['_DCDC_StaticConverter', ['#B372C4', '#7C06A6']],
    ['_InductionMotorTorqueControl', ['#B372C4', '#7C06A6']],
    ['_InductionMotorDynamic', ['#B372C4', '#7C06A6']],
]);

const DEFAULT_GRADIENT = ['#D7D7D7', '#838383'];

const getBorderStyle = (
    isOutlined: boolean | undefined,
    notificationType: null | undefined | string,
    iconType?: string
) => {
    switch (true) {
        case notificationType === ElementNotificationTypes.NEED_TO_UPDATE:
        case notificationType === ElementNotificationTypes.IS_DEPRECATED:
        case notificationType === ElementNotificationTypes.GOTO_WITHOUT_PAIR:
            return iconType === 'new'
                ? `2px solid ${uiColors.red}; box-shadow: 0 0 5px 2px ${uiColors.red};`
                : `2px solid ${uiColors.red}`;
        case notificationType === ElementNotificationTypes.PROJECT_IS_CHANGED:
        case notificationType === ElementNotificationTypes.USER_BLOCK_IS_CHANGED:
            return iconType === 'new'
                ? `2px solid ${uiColors.orange};box-shadow: 0 0 5px 2px ${uiColors.red};`
                : `2px solid ${uiColors.orange}`;
        case notificationType === ElementNotificationTypes.IS_ERROR_GETTING_PROJECT:
        case notificationType === ElementNotificationTypes.USER_BLOCK_IS_DELETED:
        case notificationType === ElementNotificationTypes.USER_BLOCK_IS_ERROR:
            return `2px solid ${uiColors.red}`;
        case isOutlined:
            return `2px solid #4af`;
        default:
            return `2px solid var(--ui-border)`;
    }
};

export const useElementStyle = (
    iconType: string | undefined,
    library: TLibraryType | null | undefined,
    subLibrary: string | null | undefined,
    isOutlined?: boolean,
    notificationType?: string | null,
    currentNodeType?: string
) => {
    const getLibraryGradient = useCallback((library: TLibraryType, subLibrary: string) => {
        const key = library === LibraryTypes.SYSTEM_DESIGN ? `${library}_${subLibrary}` : library;
        if (
            (library === LibraryTypes.SYSTEM_DESIGN ||
                library === ('cms' as LibraryTypes) ||
                library === LibraryTypes.ELECTRIC_DRIVE ||
                library === LibraryTypes.SIMULATION) &&
            !subLibrary &&
            currentNodeType
        ) {
            return oldBlocksGradientMap.get(currentNodeType) || DEFAULT_GRADIENT;
        }
        return LIBRARY_STYLES[key as keyof typeof LIBRARY_STYLES] || DEFAULT_GRADIENT;
    }, []);

    const [startColor, endColor] = getLibraryGradient(library!, subLibrary!);

    const styles = css`
        border-radius: ${radius.default};
        background: linear-gradient(180deg, ${startColor} 0%, ${endColor} 100%);
        ${isOutlined && notificationType && `border: ${getBorderStyle(isOutlined, notificationType, iconType)}`};
    `;

    return { styles, getBorderStyle };
};
