import { PayloadAction } from '@reduxjs/toolkit';

import { IWorkspaceState, TSchemaNode, WorkspaceModes } from '@repeat/models';
import { updateSchemaGroupElements } from '@repeat/services';

export const ChangeElementSlices = {
    changeElementSize: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ id: number; size: { width: number; height: number }; workspaceMode?: string }>
    ) => {
        const { size, id, workspaceMode } = payload;
        const { mode, elementId } = state.meta;
        const { elements } = state.schema.schemaItems;
        const updateElements = (schemaElements: TSchemaNode[]) => {
            return schemaElements.map((el: TSchemaNode) => {
                if (el.data.id === payload.id) {
                    return {
                        ...el,
                        height: payload.size.height,
                        width: payload.size.width,
                    };
                }
                return el;
            });
        };
        if (mode === WorkspaceModes.FSM_EDITOR) {
            const elements = state.schema.schemaItems.elements.map((el) => {
                if (el.id === elementId) {
                    const submodelItems = el.data.submodelItems || { elements: [], wires: [] };
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            submodelItems: { ...submodelItems, elements: updateElements(submodelItems.elements) },
                        },
                    };
                }
                return el;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        elements,
                    },
                },
            };
        }

        if (workspaceMode === WorkspaceModes.GROUP) {
            const { groupId } = state.meta;
            const { groups } = state.schema.schemaItems;

            if (groupId) {
                const newGroup = updateSchemaGroupElements(groups, groupId, id, (el) => ({
                    ...el,
                    width: size.width,
                    height: size.height,
                }));
                return {
                    ...state,
                    schema: {
                        ...state.schema,
                        schemaItems: {
                            ...state.schema.schemaItems,
                            elements: elements,
                            groups: newGroup,
                        },
                    },
                };
            }
        }

        const newElements = updateElements(elements);

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    changeElementRotation: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ id: number; rotation: number; workspaceMode?: string }>
    ) => {
        const { id, rotation, workspaceMode } = payload;

        const { elements } = state.schema.schemaItems;
        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === payload.id) {
                return {
                    ...el,
                    rotation: rotation,
                };
            }
            return el;
        });

        if (workspaceMode === WorkspaceModes.GROUP) {
            const { groupId } = state.meta;
            const { groups } = state.schema.schemaItems;

            if (groupId) {
                const newGroup = updateSchemaGroupElements(groups, groupId, id, (el) => ({
                    ...el,
                    rotation,
                }));

                return {
                    ...state,
                    schema: {
                        ...state.schema,
                        schemaItems: {
                            ...state.schema.schemaItems,
                            elements: newElements,
                            groups: newGroup,
                        },
                    },
                };
            }
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    changeElementCustomName: (
        state: IWorkspaceState,
        action: PayloadAction<{ id: number; name: string; workspaceMode?: string }>
    ) => {
        const { id, name, workspaceMode } = action.payload;

        const { elements } = state.schema.schemaItems;

        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === id) {
                return {
                    ...el,
                    customName: name,
                };
            }
            return el;
        });

        if (workspaceMode === WorkspaceModes.GROUP) {
            const { groupId } = state.meta;
            const { groups } = state.schema.schemaItems;
            if (groupId) {
                const newGroup = updateSchemaGroupElements(groups, groupId, id, (el) => ({
                    ...el,
                    customName: name,
                }));

                return {
                    ...state,
                    schema: {
                        ...state.schema,
                        schemaItems: {
                            ...state.schema.schemaItems,
                            elements: newElements,
                            groups: newGroup,
                        },
                    },
                };
            }
        }
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    changeElementCustomImage: (
        state: IWorkspaceState,
        action: PayloadAction<{ id: number; image: string; workspaceMode?: string }>
    ) => {
        const { id, image, workspaceMode } = action.payload;

        const { elements } = state.schema.schemaItems;
        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === id) {
                return {
                    ...el,
                    customImage: image,
                };
            }
            return el;
        });

        if (workspaceMode === WorkspaceModes.GROUP) {
            const { groupId } = state.meta;
            const { groups } = state.schema.schemaItems;
            if (groupId) {
                const newGroup = updateSchemaGroupElements(groups, groupId, id, (el) => ({
                    ...el,
                    customImage: image,
                }));

                return {
                    ...state,
                    schema: {
                        ...state.schema,
                        schemaItems: {
                            ...state.schema.schemaItems,
                            elements: newElements,
                            groups: newGroup,
                        },
                    },
                };
            }
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    changeElementExposure: (
        state: IWorkspaceState,
        action: PayloadAction<{ id: number; zIndex?: number; workspaceMode?: string }>
    ) => {
        const { id, zIndex, workspaceMode } = action.payload;

        const { groupId } = state.meta;
        const { elements } = state.schema.schemaItems;
        const newElements = elements.map((el: TSchemaNode) => {
            if (el.data.id === id) {
                const { zIndex: currentZIndex, ...rest } = el;
                const updatedBlock = currentZIndex ? { ...rest } : { ...el, zIndex };
                return {
                    ...updatedBlock,
                };
            }
            return el;
        });

        if (workspaceMode === WorkspaceModes.GROUP) {
            const { groupId } = state.meta;
            const { groups } = state.schema.schemaItems;
            if (groupId) {
                const newGroup = updateSchemaGroupElements(groups, groupId, id, (el) => {
                    if (el.data.id === id) {
                        const { zIndex: currentZIndex, ...rest } = el;
                        const updatedBlock = currentZIndex ? { ...rest } : { ...el, zIndex };
                        return {
                            ...updatedBlock,
                        };
                    }
                    return el;
                });

                return {
                    ...state,
                    schema: {
                        ...state.schema,
                        schemaItems: {
                            ...state.schema.schemaItems,
                            elements: newElements,
                            groups: newGroup,
                        },
                    },
                };
            }
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
};
