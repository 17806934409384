import { MarkerType, XYPosition } from 'reactflow';

import { v5 as uuidv5 } from 'uuid';

import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';

import { FSM_ELEMENT_TYPE, generateTitleByAlphabet, setDefaultPort } from '@repeat/constants';
import {
    BlockGoToTypes,
    ElemParams,
    ElemProps,
    ILibraryItem,
    ISchemaGoToMap,
    ISchemaGoToMapItem,
    SolverTypes,
    TBlockGoToType,
    TConnection,
    TElement,
    TSchemaConnection,
    TSchemaHandle,
    TSchemaNode,
    TWorkspaceMode,
    WireTypes,
    WorkspaceModes,
} from '@repeat/models';

const UUID_GOTO_NAMESPACE = '53fcfeac-c59f-4c1f-8cb1-cf5a48b51460';

export const findGoToBlockIdsWithPair = (goToMap: ISchemaGoToMap | null | undefined): string[] => {
    if (!goToMap) {
        return [];
    }

    let blockIds: string[] = [];
    Object.keys(goToMap).forEach((uuid: string) => {
        const item = goToMap[uuid];
        if (item.goFromIds.length > 0 && item.goToId !== null) {
            blockIds = [...blockIds, item.goToId];
        }
    });

    return blockIds;
};
export const findNotUniqueGoToBlockIds = (goToMap: ISchemaGoToMap | null | undefined): string[] => {
    if (!goToMap) {
        return [];
    }

    let blockIds: string[] = [];
    Object.keys(goToMap).forEach((uuid: string) => {
        const item = goToMap[uuid];
        if (item.notUniqueGoToIds !== undefined && item.notUniqueGoToIds.length > 0) {
            blockIds = [...blockIds, ...item.notUniqueGoToIds];
        }
    });

    return blockIds;
};
export const findGoToBlockIdsWithoutPair = (goToMap: ISchemaGoToMap | null | undefined): string[] => {
    if (!goToMap) {
        return [];
    }

    let blockIds: string[] = [];
    Object.keys(goToMap).forEach((uuid: string) => {
        const item = goToMap[uuid];
        if (item.goToId === null) {
            blockIds = [...blockIds, ...item.goFromIds];
        }
        if (item.goFromIds.length === 0 && item.goToId !== null) {
            blockIds = [...blockIds, item.goToId];
        }
    });

    return blockIds;
};
export const isGoToBlockWithoutPair = (uuid: string, goToMap: ISchemaGoToMap | null | undefined): boolean => {
    if (!goToMap || !goToMap[uuid]) {
        return false;
    }

    return goToMap[uuid].goToId === null || goToMap[uuid].goFromIds.length === 0;
};

const findAnyGoToBlockUuidWithoutPair = (
    type: TBlockGoToType,
    goToMap: ISchemaGoToMap | null | undefined
): string | null => {
    if (!goToMap) {
        return null;
    }

    let blockUuid: string | null = null;

    Object.keys(goToMap).forEach((uuid: string) => {
        if (type === BlockGoToTypes.GOTO) {
            if (blockUuid === null && goToMap[uuid].goToId === null) {
                blockUuid = uuid;
            }
        } else {
            if (blockUuid === null && goToMap[uuid].goFromIds.length === 0) {
                blockUuid = uuid;
            }
        }
    });

    return blockUuid;
};

export const makeGoToTagId = (title: string) => {
    return uuidv5(title, UUID_GOTO_NAMESPACE);
};

export interface INodeFactoryParams {
    id: number;
    index: string;
    position: XYPosition;
    nodeType: string;
    elementProps?: ElemProps[];
    elementParams?: ElemParams[];
    selectedConfiguration?: any;
    availablePorts?: TSchemaHandle[];
    indicator?: { connectedElementId: number; parameter: string; unitsPropValue: string; unitsFactor?: string };
}

export const makeSchemaNode = (
    item: ILibraryItem,
    params: INodeFactoryParams,
    portTypes: TPortTypeComplex[],
    goToMap?: ISchemaGoToMap | null
): TSchemaNode => {
    let elementProperties = params.elementProps || item.elemProps;
    if ([BlockGoToTypes.GOTO, BlockGoToTypes.GOFROM].includes(item.type as TBlockGoToType)) {
        const foundUuid = findAnyGoToBlockUuidWithoutPair(item.type as TBlockGoToType, goToMap);
        const foundWithoutPairInfo = foundUuid !== null && goToMap ? goToMap[foundUuid] : null;
        const usedTitles = goToMap ? Object.values(goToMap).map((item: ISchemaGoToMapItem) => item.title) : [];

        const title = foundWithoutPairInfo !== null ? foundWithoutPairInfo.title : generateTitleByAlphabet(usedTitles);
        const uuid = foundUuid !== null ? foundUuid : makeGoToTagId(title);

        elementProperties = elementProperties.map((property: ElemProps) => {
            if (property.name === 'tagId') {
                return {
                    ...property,
                    value: uuid,
                };
            }
            if (property.name === 'tagTitle') {
                return {
                    ...property,
                    value: title,
                };
            }
            return property;
        });
    }

    let view = item.view || {
        isRotatable: true,
        isImageRotatable: true,
        isResizable: true,
    };
    if (item.type === 'userBlock') {
        view = { ...view, isResizable: true };
    }

    let element: TElement = {
        isActive: item.isActive,
        isDisabled: item.isDisabled || true,
        id: params.id,
        index: params.index,
        picId: item.picId,
        name: item.name,
        shortName: item.shortName,
        type: item.type,
        subtype: item.subtype,
        library: item.library,
        subLibrary: item.subLibrary,
        solver: SolverTypes.MDCORE,
        description: item.description,
        elemParams: params.elementParams || item.elemParams,
        elemProps: elementProperties,
        stateParameters: item.stateParameters || [],
        parametersToDisplay: item.parametersToDisplay || null,
        propertiesToDisplay: item.propertiesToDisplay || null,
        availablePorts:
            params.availablePorts ||
            item.availablePorts
                ?.filter((port) => {
                    const param = item.elemParams.find((p) => p.name === port.name);

                    return param && (param?.isVisible || !Object.prototype.hasOwnProperty.call(param, 'isVisible'));
                })
                .map((p) => {
                    const portTypeInfo = portTypes.find((item) => item.type === p.typeConnection);
                    if (!portTypeInfo) {
                        const { direction, compatibleTypes } = setDefaultPort(p.name, portTypes);
                        return {
                            ...p,
                            isConnected: false,
                            direction,
                            compatibleTypes,
                        };
                    }
                    const { type, ...rest } = portTypeInfo;

                    return { ...p, isConnected: false, ...rest };
                }) ||
            [],
        isViewOnly: item.isViewOnly,
        view,
        rules: item.rules || [],
        hasConfigurations: item.hasConfigurations || false,
        selectedConfiguration: params.selectedConfiguration || null,
        isFromFile: item.isFromFile || false,
        isIndicator: item.isIndicator || false,
        blockId: item.blockId || null,
        userId: item.userId || null,
        image: item.image || null,
        hash: item.hash || null,
        // TODO remove below props
        indicator: params?.indicator,
        portsAmount: item.portsAmount,
        hasPorts: item.hasPorts,
        hasManagedPorts: item.hasManagedPorts,
        isNeedToUpdate: false,
        isDeprecated: item.isDeprecated || false,
        maintainedTo: item.maintainedTo,
        proxyMap: item.proxyMap || null,
        ...(item.type === FSM_ELEMENT_TYPE && { submodelItems: { elements: [], wires: [] } }),
    };

    if (item?.modules) {
        element = {
            ...element,
            modules: item.modules,
        };
    }

    return {
        id: `${params.id}`,
        type: params.nodeType,
        position: params.position,
        data: element,
        meta: {
            isViewInitialized: false,
            isDataInitialized: true,
        },
    } as TSchemaNode;
};

export interface IConnectionFactoryParams {
    id: number;
    index: string;
    source: string | null;
    target: string | null;
    sourceHandle: string | null;
    targetHandle: string | null;
    type?: string;
    condition?: string | null;
    action?: string | null;
}

export const makeSchemaConnection = (params: IConnectionFactoryParams, mode?: TWorkspaceMode): TSchemaConnection => {
    const connection: TConnection = {
        id: params.id,
        type: WireTypes.WIRE,
        index: params.index,
        wireParams: [],
        wireProps: [],
        isValidConnection: true,
        condition: params.condition || null,
        action: params.action || null,
    };

    return {
        id: `${params.id}`,
        type: mode === WorkspaceModes.FSM_EDITOR ? 'floating' : 'custom-edge',
        source: params.source,
        target: params.target,
        sourceHandle: params.sourceHandle,
        targetHandle: params.targetHandle,
        data: connection,
        markerEnd: {
            type: MarkerType.ArrowClosed,
        },
    } as TSchemaConnection;
};
