import { TWorkspaceMode, WorkspaceModes } from '@repeat/models';

export const useReadOnlyMode = (workspaceMode: TWorkspaceMode, prevWorkspaceMode: TWorkspaceMode) => {
    const isReadOnlyMode =
        workspaceMode !== WorkspaceModes.DEMO &&
        workspaceMode !== WorkspaceModes.PREVIEW &&
        prevWorkspaceMode !== WorkspaceModes.DEMO &&
        prevWorkspaceMode !== WorkspaceModes.PREVIEW &&
        workspaceMode !== WorkspaceModes.SUBMODEL;
    return { isReadOnlyMode };
};
