import { ChangeEvent, FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { NodeToolbar, Position } from 'reactflow';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { IconButton, uiColors } from '@repeat/ui-kit';

import {
    SElementControlPanel,
    SElementCustomNameWrapper,
    SElementEditCustomNameText,
    SElementEditCustomNameTextarea,
} from '../SComponents';

interface IElementEditCustomName {
    id: number;
    rotation: number;
    selected: boolean;
    defaultValue: string;
}

const adjustTextAreaHeight = (textAreRef: MutableRefObject<HTMLTextAreaElement>) => {
    if (textAreRef && textAreRef.current !== null) {
        textAreRef.current.style.height = 'auto';
        textAreRef.current.style.height = `${textAreRef.current.scrollHeight}px`;
    }
};

export const ElementEditCustomName: FC<IElementEditCustomName> = ({ defaultValue, id, selected, rotation }) => {
    const dispatch = useAppDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [isValueChanged, setIsValueChanged] = useState(false);
    const textAreRef = useRef<HTMLTextAreaElement | null>(null);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);

    useEffect(() => {
        if (!selected) {
            setIsEdit(false);
        }
    }, [selected]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLTextAreaElement;
            const currentValue = target.value;
            if (!isValueChanged && defaultValue !== value) {
                setIsValueChanged(true);
            }
            setTimeout(() => setValue(currentValue), 0);
        },
        [isValueChanged, value]
    );

    const handleSave = useCallback(
        (value: string) => {
            dispatch(workspaceActions.changeElementCustomName({ id, name: value, workspaceMode }));
            setIsEdit(false);
        },
        [value, id]
    );

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent) => {
            adjustTextAreaHeight(textAreRef as MutableRefObject<HTMLTextAreaElement>);
            const target = event.target as HTMLTextAreaElement;
            if (event.code === 'Enter' || event.code === 'Escape') {
                handleSave(target.value);
            }
        },
        [textAreRef]
    );

    const handleEditCustomName = useCallback((val: boolean) => {
        setIsEdit(val);
        if (val) {
            setTimeout(() => {
                const target = textAreRef.current as HTMLTextAreaElement;
                target.setSelectionRange(target.value.length, target.value.length);
                adjustTextAreaHeight(textAreRef as MutableRefObject<HTMLTextAreaElement>);
            }, 100);
        }
    }, []);

    return (
        <>
            <NodeToolbar isVisible={selected} align={'start'} offset={8} position={Position.Right}>
                <SElementControlPanel selected={selected}>
                    {!isEdit && (
                        <IconButton
                            fill={isEdit ? uiColors.mainBlue : uiColors.darkGrey}
                            noHover
                            name={'edit'}
                            onClick={() => handleEditCustomName(true)}
                        />
                    )}
                    {isEdit && (
                        <IconButton
                            fill={isEdit ? uiColors.mainBlue : uiColors.darkGrey}
                            noHover
                            name={isValueChanged ? 'done' : 'close'}
                            onClick={() => {
                                if (isValueChanged) {
                                    return handleSave(value);
                                }
                                return setIsEdit(false);
                            }}
                        />
                    )}
                </SElementControlPanel>
            </NodeToolbar>

            <SElementCustomNameWrapper rotation={rotation}>
                {!isEdit && <SElementEditCustomNameText>{value}</SElementEditCustomNameText>}
                {isEdit && (
                    <SElementEditCustomNameTextarea
                        onKeyDown={(event: React.KeyboardEvent) => handleKeyPress(event)}
                        ref={textAreRef}
                        maxLength={56}
                        defaultValue={value}
                        autoFocus={true}
                        onChange={(event) => handleChange(event)}
                    />
                )}
            </SElementCustomNameWrapper>
        </>
    );
};
