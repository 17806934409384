import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.ELEMENT_EXPOSURE_TOOLTIP]: {
        id: TranslationKey.ELEMENT_EXPOSURE_TOOLTIP,
        defaultMessage: ru[TranslationKey.ELEMENT_EXPOSURE_TOOLTIP],
    },
    [TranslationKey.ELEMENT_EXPOSURE_TOOLTIP_TOP]: {
        id: TranslationKey.ELEMENT_EXPOSURE_TOOLTIP_TOP,
        defaultMessage: ru[TranslationKey.ELEMENT_EXPOSURE_TOOLTIP_TOP],
    },
    [TranslationKey.ELEMENT_ADD_IMAGE_TOOLTIP]: {
        id: TranslationKey.ELEMENT_ADD_IMAGE_TOOLTIP,
        defaultMessage: ru[TranslationKey.ELEMENT_ADD_IMAGE_TOOLTIP],
    },
});
